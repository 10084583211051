import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { AxiosError } from 'axios'

import { logDev } from '../../../../components/LogDev'
import { getModifyBookingClient } from '../../../../rest/clients'
import { trackEvent } from '../../../../providers/TelemetryProvider'
import { useKeycloak } from '../../../../providers/KeycloakProvider'
import useQueryParams, { QueryParamsEnum } from '../../../../hooks/useQueryParams'
import { useBookingDetails } from '../../../../providers/BookingDetailsProvider'

function UpdatePassengers() {
  const location = useLocation()
  const { bookingCode, rowNumber } = useParams()
  const [loadingUpdatePassengers, setLoadingUpdatePassengers] = useState(false)
  const [error, setError] = useState<AxiosError | undefined>(undefined)
  const [success, setSuccess] = useState(false)
  const { token, isAuthenticated: kc_isAuth, isLoading: kc_isLoading } = useKeycloak()
  const isAuthenticated = kc_isAuth
  const [accessToken, setAccessToken] = useState<string>('')
  const { setQueryParam, deleteQueryParam } = useQueryParams()

  useEffect(() => {
    if (!kc_isLoading && kc_isAuth && token) {
      setAccessToken(token)
    }
  }, [kc_isAuth, kc_isLoading, token])

  function updatePassengers(passengers) {
    setQueryParam(QueryParamsEnum.isUpdatingPassengers, rowNumber?.toString() || '')

    setLoadingUpdatePassengers(true)
    if (bookingCode && accessToken && isAuthenticated) {
      getModifyBookingClient(accessToken)
        ?.updatePassengers(bookingCode, passengers)
        .then(() => {
          setSuccess(true)
          setError(undefined)
          trackEvent('user updated passengers')
        })
        .catch((error) => {
          //Handle error
          setError(error)
          setSuccess(false)
          logDev(error)
          trackEvent('user failed to update passengers')
          setLoadingUpdatePassengers(false)
          deleteQueryParam(QueryParamsEnum.isUpdatingPassengers, location)
        })
        .finally(() => {
          setLoadingUpdatePassengers(false)
        })
    }
  }

  return {
    updatePassengers,
    loadingUpdatePassengers,
    error,
    success,
  }
}

export default UpdatePassengers
