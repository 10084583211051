import React, { JSX, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { Add, Button, Divider, HeaderWithIcon, Spinner, Ticket } from '@fjordline/styles-v3'

import { ButtonFull } from '../../components/globalStyles'
import UseGetInboundExtras from '../../graphql/availabilityHooks/extras/UseGetInboundExtras'
import UseGetOutboundExtras from '../../graphql/availabilityHooks/extras/UseGetOutboundExtras'
import { Extra } from '../../graphql/types'
import { routerBookingSubPathsStrings } from '../../routes/appRoutes/routerPaths'
import { AddExtrasOnboardNavLink } from '../../routes/navLinkFunctions'

import ExtrasOnboard from './sectionExtrasOnboard/ExtrasOnboard'
import { MinSideContainer } from './booking.styles'
import { useBookingDetails } from '../../providers/BookingDetailsProvider'
import { isPastDeadline } from '../../utils/isPastDeadline'

export default function SectionExtrasOnboard({
  bookingCode,
  inboundExtrasOnboard,
  journeyPorts,
  outboundExtraOnboard,
}: {
  bookingCode: string
  inboundExtrasOnboard: Extra[]
  journeyPorts: string
  outboundExtraOnboard: Extra[]
}): JSX.Element | null {
  const { flBooking } = useBookingDetails()
  const modifyBookingDeadline = flBooking?.modificationDeadline
  const { t } = useTranslation()
  const hasExtrasOnboard = useMemo<boolean>(
    () =>
      (inboundExtrasOnboard !== undefined && inboundExtrasOnboard.length > 0) ||
      (outboundExtraOnboard !== undefined && outboundExtraOnboard.length > 0),
    [inboundExtrasOnboard, outboundExtraOnboard],
  )
  const hasInboundExtrasOnboard = useMemo<boolean>(
    () => inboundExtrasOnboard !== undefined && inboundExtrasOnboard.length > 0,
    [inboundExtrasOnboard],
  )
  const hasOutboundExtrasOnboard = useMemo<boolean>(
    () => outboundExtraOnboard !== undefined && outboundExtraOnboard.length > 0,
    [outboundExtraOnboard],
  )

  const { outboundExtrasOnboard: outboundAvailable, loading: outboundLoading } = UseGetOutboundExtras(bookingCode)
  const { inboundExtrasOnboard: inboundAvailable, loading: inboundLoading } = UseGetInboundExtras(bookingCode)

  const loading = outboundLoading || inboundLoading
  const hasExtrasAvailable =
    (outboundAvailable && outboundAvailable.length > 0) || (inboundAvailable && inboundAvailable.length > 0)
      ? true
      : false

  return (
    <div style={{ marginTop: '1rem' }} data-cy="section-extrasOnboard">
      {hasExtrasOnboard ? (
        <>
          <HeaderWithIcon icon={Ticket}>{t('component.extras.misc.onboardTitle')}</HeaderWithIcon>
          <MinSideContainer>
            <ExtrasOnboard extras={outboundExtraOnboard} />
            {hasInboundExtrasOnboard ? (
              <>
                {hasOutboundExtrasOnboard && <Divider style={{ marginBottom: '1rem' }} />}
                <ExtrasOnboard isInbound extras={inboundExtrasOnboard} />
              </>
            ) : null}
          </MinSideContainer>
        </>
      ) : null}
      {hasExtrasAvailable && !loading && !isPastDeadline({ modifyBookingDeadline }) ? (
        <NavLink
          to={AddExtrasOnboardNavLink({ bookingCode: bookingCode, backTargetTitle: journeyPorts })}
          key={routerBookingSubPathsStrings.ADD_EXTRAS_ONBOARD}
        >
          <ButtonFull>
            <Button
              disabled={loading}
              dashedBorder
              theme="ghost"
              style={{ marginBottom: '1rem' }}
              size="medium"
              leadingIcon={loading ? Spinner : Add}
              dataCy="btn-section-add-extras-onboard"
            >
              {t('label.button.addMoreExtraOnboard')}
            </Button>
          </ButtonFull>
        </NavLink>
      ) : null}
    </div>
  )
}
