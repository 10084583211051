/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js'
import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web'
import React from 'react'
import { APP_INSIGHTS_KEY } from '../config'

export const reactPlugin = new ReactPlugin()
let appInsights = undefined as ApplicationInsights | undefined

export const trackError = (error: string | Error, properties?: any) => {
  return appInsights?.trackException({
    error: typeof error === 'string' ? new Error(error) : error,
    properties,
    severityLevel: SeverityLevel.Error,
  })
}

export const trackAndThrow = (error: Error) => {
  trackError(error)
  throw error
}

//Track Event function
export const trackEvent = (event, properties: { [key: string]: any} | undefined = undefined) => {
  return appInsights?.trackEvent({ name: event }, properties)
}

const TelemetryProvider: React.FC = ({ children }: any) => {
  if (APP_INSIGHTS_KEY) {
    appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: APP_INSIGHTS_KEY,
        enableAutoRouteTracking: true,
        extensions: [reactPlugin],
      },
    })

    appInsights.loadAppInsights()
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}

export default withAITracking(reactPlugin, TelemetryProvider, 'TelemetryProvider')
