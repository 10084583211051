import React, { useEffect, useState } from 'react'
import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache, from } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { RetryLink } from '@apollo/client/link/retry'
import { ENVIRONMENT, GRAPHQL_URL } from '../config'
import { ContextChildren } from '../providers/genericTypes'
import { useKeycloak } from '../providers/KeycloakProvider'
import { onError } from '@apollo/client/link/error'
import { trackEvent } from '../providers/TelemetryProvider'

const MyPageApolloClient: React.FC<React.PropsWithChildren<ContextChildren>> = ({ children }) => {
  /*
## This cache setting is added because a bug was happening where the first item in a list was being duplicated. (You can replicate if you remove the cache setting here to normal.
## Normal: const cache = new InMemoryCache({}) , and go look at the passenger list in Booking Details. All passengers should be duplicated. 
## You can either fix by setting this cache setting, or disabling cache with 'no-cache' in the bookingDetails hook for graphql. 
## Items in your array get "normalized" to the same values in the Apollo cache. AKA, they look the same to Apollo. This usually happens because they share the same Symbol(id).
## If you print out your Apollo response object, you'll notice that each of the objects have Symbol(id) which is used by Apollo cache. Your array items probably have the same Symbol(id) which causes them to repeat.
*/
  // const cache = new InMemoryCache({})
  const cache = new InMemoryCache({
    dataIdFromObject: (o) => (o._id ? `${o.__typename}:${o._id}` : undefined),
    typePolicies: {
      Query: {
        fields: {
          // Add a keyArgs function to the field's configuration
          // to tell Apollo Client to use the `args` object as the cache key
          getBookingDetails: {
            keyArgs: ['bookingCode'],
          },
          GetCustomer: {
            keyArgs: ['customerCode'],
          },

          // Add a merge function to the field's configuration
          // to tell Apollo Client to merge the existing data with the new data
          // instead of overwriting it
        },
      },
    },
  })

  const errorLink = onError(({ operation, graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        trackEvent(`[GraphQL error] Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}, OperationName: ${operation.operationName}`)
      })
    }

    if (networkError) {
      trackEvent(`[Network error]: ${networkError}`)
    }

    const { response } = operation.getContext()

    if (!response) return

    trackEvent(`user got ${response.status} error QUERY: ${operation.operationName}`)
  })

  const httpLink = new HttpLink({
    uri: ({ operationName }) => `${GRAPHQL_URL}${operationName}`,
  })
  const retry = new RetryLink()
  const sessionStorageToken = sessionStorage.getItem('token')

  const { isAuthenticated: kc_isAuthenticated, token: kc_token } = useKeycloak()

  const [token, setToken] = useState<string>(sessionStorageToken || '')

  useEffect(() => {
    if (kc_isAuthenticated && kc_token) {
      setToken(kc_token)
      sessionStorage.setItem('token', kc_token)
    }
  }, [kc_isAuthenticated, kc_token])

  const authLink = setContext(async () => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  })
  const apolloClientProd = new ApolloClient({
    link: from([authLink, retry, errorLink, httpLink]),
    cache: cache,
  })

  const apolloClientDev = new ApolloClient({
    link: from([authLink, retry, errorLink, httpLink]),
    cache: cache,
  })

  const apolloClient = ENVIRONMENT === 'DEV' ? apolloClientDev : apolloClientProd

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
}

export default MyPageApolloClient
