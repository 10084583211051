/**
 * https://react.i18next.com/latest/using-with-hooks
 */

import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import locales from './locales'
import { translation } from './locales/nb'
import LanguageDetector from 'i18next-browser-languagedetector'

const resolvePath = (path, obj, target) => {
  for (const k in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(k))
      if (obj[k] === target) return path + '.' + k
      else if (typeof obj[k] === 'object') {
        const result = resolvePath(path + '.' + k, obj[k], target)
        if (result) return result
      }
  }
  return false
}

const i18nKey = (target) => {
  const path = target.split('.')?.[0]
  return resolvePath(path, translation, target).substring(path.length + 1)
}

export const useTrans = () => {
  return {
    i18nKey,
    i18nIndex: translation,
  }
}

const options = {
  // order and from where user language should be detected
  order: ['localStorage'],
  // keys or params to lookup language from
  lookupLocalStorage: 'FL_bookingv2_i18nextLng',
}

// checks for query parameter 'lang' in URL - use as defaultLanguage if it exists
const urlSearchParams = new URLSearchParams(window.location.search)
const params = Object.fromEntries(urlSearchParams.entries())

const localStorage = window.localStorage
const defaultLanguage = localStorage.getItem('FL_bookingv2_i18nextLng') || 'nb'

const supportedLanguages = Object.keys(locales)

const currentLangInLocalStorage = localStorage.getItem('FL_bookingv2_i18nextLng')

if (currentLangInLocalStorage && !supportedLanguages.includes(currentLangInLocalStorage)) {
  localStorage.setItem('FL_bookingv2_i18nextLng', 'nb')
}


i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    lng: currentLangInLocalStorage ?? defaultLanguage,
    fallbackLng: 'en',
    supportedLngs: supportedLanguages,

    detection: options,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      format: (value: string, format?: string) => {
        if (format === 'lowercase') return (value as string)?.toLowerCase()
        return value
      },
    },
    resources: locales,
    missingKeyHandler: (lng, ns, key, fallbackValue) => {
      // eslint-disable-next-line no-console
      console.error('ns:', ns, fallbackValue)
      // eslint-disable-next-line no-console
      console.error('Missing key in translations:', key, lng)
    },
  })
