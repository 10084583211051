import { useEffect, useState } from 'react'
import { ApolloError } from '@apollo/client'

import { GroupedBookingResponse, useGetBookingsQuery } from '../types'
import { useKeycloak } from '../../providers/KeycloakProvider'
import { trackError } from '../../providers/TelemetryProvider'

type UseGetBookingsType = {
  getBookingsError?: ApolloError | undefined
  bookings?: GroupedBookingResponse | undefined
  bookingsLoading: boolean
}

const UseGetBookings: () => UseGetBookingsType = () => {
  const [bookings, setFlCustomer] = useState<UseGetBookingsType>({} as UseGetBookingsType)
  const { isAuthenticated: kc_isAUth } = useKeycloak()
  const isAuthenticated = kc_isAUth
  const { data, loading, error } = useGetBookingsQuery({
    skip: !isAuthenticated,
  })

  useEffect(() => {
    if (!loading && error !== undefined) {
      trackError(error, {
        location: 'UseGetBookings',
        function: 'useGetBookingsQuery',
        isAuthenticated: isAuthenticated,
      })
    }
  }, [loading, error, isAuthenticated])

  useEffect(() => {
    setFlCustomer({
      bookings: data?.bookings as GroupedBookingResponse,
      bookingsLoading: loading,
      getBookingsError: error,
    })
  }, [data, error, loading])

  return bookings
}

export default UseGetBookings
