import { useAccordionState, Accordion, AccordionItem, Row, Column, Label } from '@fjordline/styles-v3'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import StepperStateWrapper from '../../../../../components/StepperStateWrapper'
import { AvailabilityMealItemWithSpec, MealsDetailsFragment, Specification } from '../../../../../graphql'
import UseGetBookingDetails from '../../../../../graphql/customerHooks/UseGetBookingDetails'
import { useTimeZonedDateFormatter } from '../../../../../hooks/DateFormat'
import {
  useAvailabilityItems,
  useAvailabilityItemsOperations,
} from '../../../../../providers/AvailabilityItemsProvider'
import useOnChangeAccordion from './AddMealOnAccordionItem'
import { findPassengerQuantityPerFareCode, findMaxToBook, getMealCount } from '../utils'
import useFetchMeals from '../../../../../sanity/meals/useFetchMeals'
import { imageUrlFor } from '../../../../../sanity/imageUrlBuilder'
import { PortableText } from '@portabletext/react'
import styled from 'styled-components'
import useFetchSpecifications from '../../../../../sanity/specifications/useFetchSpecifications'
import Skeleton from 'react-loading-skeleton'

export type AccordionMealProps = {
  item: AvailabilityMealItemWithSpec
  itemsWithSameStartTime: Record<string, MealsDetailsFragment[]> | undefined
  isOutbound: boolean
  meals: AvailabilityMealItemWithSpec[]
}

const AccordionMeal: React.FC<AccordionMealProps> = ({
  item,

  itemsWithSameStartTime,
  isOutbound,

  meals,
}: AccordionMealProps) => {
  const { i18n, t } = useTranslation()
  const d = useTimeZonedDateFormatter()
  const { bookingCode } = useParams()
  const { flBooking } = UseGetBookingDetails({ bookingCode: bookingCode || '' })
  const { addToCartState } = useAvailabilityItems()
  const { openedItems, onToggle } = useAccordionState({ multiOpen: true })

  const { onChangeAccordion } = useOnChangeAccordion({
    isOutbound: isOutbound,
  })

  const mealsToAdd = addToCartState?.[bookingCode || '']?.[isOutbound ? 'outbound' : 'inbound']?.meals
  const checkedMeals = mealsToAdd?.map((item) => {
    return `${new Date(item.startTime).getDate()} itemType: ${item.itemType}`
  })

  const mealSpecsToShow = flBooking?.outbound?.passengers?.map((pass) => {
    if (pass.fareCode === 'ADULT') return 'ADL'
    if (pass.fareCode === 'CHILD') return 'CHD'
    if (pass.fareCode === 'INFANT') return 'INF'

    return pass.fareCode as string
  })
  const checkedMealsAccordionItem = mealsToAdd?.map((item) => {
    return `${new Date(item.startTime)} itemCode: ${item.code}`
  })

  const { data: mealFromSanity, isLoading } = useFetchMeals({ code: item.code })
  const [openedMeal, setOpenedMeal] = useState<string[]>(
    mealsToAdd?.map((meal) => `${new Date(meal.startTime)} itemCode: ${meal.code}`),
  )

  const onToggleMeal = (value) => {
    setOpenedMeal((prev) => {
      if (!prev) return [value]
      if (prev?.includes(value)) {
        return prev?.filter((item) => item !== value)
      }
      return [...prev, value]
    })
  }

  if (item.specifications?.every((e) => e.hidePrice === true)) return null

  return (
    <Accordion
      variant="panel"
      onToggle={onToggle}
      openedItems={openedItems}
      values={checkedMeals}
      data-cy={`accordion-${item.code}-${item.startTime}`}
    >
      <AccordionItem
        title={mealFromSanity?.mealName?.[i18n.language] ?? item.itemName ?? item.code}
        itemId={`${new Date(item.startTime as string).getDate()} itemType: ${item.itemType}`}
        data-cy={`accordion-item-${item.code}-${item.startTime}`}
      >
        <div>
          <Row>
            <Column large={4} medium={4}>
              {imageUrlFor(mealFromSanity?.mealImages?.[0]?.asset._ref)?.url() ? (
                <img
                  width="100%"
                  src={imageUrlFor(mealFromSanity?.mealImages[0].asset._ref)?.url()}
                  alt={mealFromSanity?.mealName?.[i18n.language] || ''}
                  style={{ borderRadius: '4px', maxHeight: '300px' }}
                />
              ) : null}
            </Column>
            {!isLoading ? (
              <Column large={8} medium={8}>
                <StyledPortableText>
                  <PortableText value={mealFromSanity?.mealFullDesc?.[i18n.language]} />
                </StyledPortableText>
              </Column>
            ) : (
              <SkeletonWrapper>
                <div style={{ marginTop: '1rem' }} />
                <Skeleton count={6.5} />
              </SkeletonWrapper>
            )}
          </Row>

          {Object.values(itemsWithSameStartTime ?? []).map((itemWithSameStartTimeItem) => {
            const items = itemWithSameStartTimeItem as AvailabilityMealItemWithSpec[]
            return (
              <div key={items?.[0]?.code}>
                <Row style={{ marginTop: '0.5rem' }}>
                  <Column>
                    <div>
                      <Label>
                        <span>{t('component.extras.meal.chooseTime')}</span>
                      </Label>
                    </div>
                  </Column>
                </Row>
                <div>
                  <Accordion
                    data-cy={`accordion-${item.code}`}
                    variant="checkbox"
                    values={checkedMealsAccordionItem}
                    openedItems={openedMeal}
                    onToggle={onToggleMeal}
                    onChange={(e) => {
                      onChangeAccordion(e)
                    }}
                  >
                    {items.map((item) => {
                      const filterSameCategoryAndStartTime = mealsToAdd?.filter(
                        (e) =>
                          e.itemType === item.itemType &&
                          new Date(e.startTime).getDate() === new Date(item.startTime).getDate(),
                      )

                      if (
                        filterSameCategoryAndStartTime?.find(
                          (e) =>
                            e.itemType === item.itemType &&
                            new Date(e.startTime).getDate() === new Date(item.startTime).getDate(),
                        ) &&
                        filterSameCategoryAndStartTime.every((e) => e.code !== item.code)
                      ) {
                        return null
                      }

                      return (
                        <AccordionItem
                          key={item.code + item.legCode}
                          title={d(item.startTime as string, 'HH:mm') as string}
                          itemId={`${new Date(item.startTime as string)} itemCode: ${item.code}`}
                          data-cy={`accordion-item-${item.legCode}-${item.code}-${item.startTime}`}
                        >
                          <Row>
                            {item.specifications?.map((spec) => {
                              const itemOfSameCategoryExistInCart = mealsToAdd?.find(
                                (e) =>
                                  new Date(e?.startTime as Date).getDay() ===
                                  new Date(item.startTime as string).getDay(),
                              )
                              const differentMealTimeExist =
                                meals?.find(
                                  (e) =>
                                    new Date(e?.startTime as string).getDay() ===
                                    new Date(item.startTime as string).getDay(),
                                ) !== undefined

                              const normalSpecCodeForFareCodes = ['ADL', 'CHD', 'INF', 'BABY']
                              //Dont show specs/fareCodes that are not included in the booking (If no child, dont show child meal)
                              if (
                                spec.specificationCode &&
                                normalSpecCodeForFareCodes.some((e) => e === spec.specificationCode) &&
                                !mealSpecsToShow?.includes(spec.specificationCode)
                              )
                                return null

                              return (
                                <MealSpecItem
                                  key={item.code + spec.specificationCode + item.legCode}
                                  isOutbound={isOutbound}
                                  differentMealTimeExist={differentMealTimeExist}
                                  spec={spec}
                                  item={item}
                                  itemOfSameCategoryExistInCart={itemOfSameCategoryExistInCart}
                                />
                              )
                            })}
                          </Row>
                        </AccordionItem>
                      )
                    })}
                  </Accordion>
                </div>
              </div>
            )
          })}
        </div>
      </AccordionItem>
    </Accordion>
  )
}

export default AccordionMeal

const SkeletonWrapper = styled.div`
  width: 100%;
  flex: 1;

  span {
    width: 100%;
  }
`

export const StyledPortableText = styled.div`
  p {
    margin-bottom: 0;
    margin-top: 1rem;
  }

  * {
    margin: 0;
  }

  h3 {
    margin-top: 0;
    margin-bottom: 1rem;
  }
`

type MealSpecItemProps = {
  spec: Specification
  item: AvailabilityMealItemWithSpec
  differentMealTimeExist: boolean
  itemOfSameCategoryExistInCart: MealsDetailsFragment | undefined

  isOutbound: boolean
}
const MealSpecItem: React.FC<MealSpecItemProps> = ({
  spec,
  item,
  differentMealTimeExist,
  itemOfSameCategoryExistInCart,
  isOutbound,
}: MealSpecItemProps) => {
  const { addMealsToCart } = useAvailabilityItemsOperations()
  const { addToCartState } = useAvailabilityItems()
  const { bookingCode } = useParams()
  const { flBooking } = UseGetBookingDetails({ bookingCode: bookingCode || '' })
  const mealsToAdd = addToCartState?.[bookingCode || '']?.[isOutbound ? 'outbound' : 'inbound']?.meals
  const { adults, children, infants, baby } = findPassengerQuantityPerFareCode(flBooking?.outbound?.passengers ?? [])
  const { maxToBook, disableMeal } = findMaxToBook(spec, adults, children, infants, baby, item, mealsToAdd)
  const { i18n } = useTranslation()
  const { specByCode } = useFetchSpecifications(spec.specificationCode)

  return (
    <Column key={spec.specificationCode}>
      <StepperStateWrapper
        data-cy={`stepper-${spec.specificationCode}`}
        id={spec.specificationCode || ''}
        label={specByCode?.specificationName?.[i18n.language] || spec.specificationCode}
        allowZero={true}
        disabled={
          (differentMealTimeExist &&
            itemOfSameCategoryExistInCart?.itemType === item.itemType &&
            item.legCode !== itemOfSameCategoryExistInCart?.legCode) ||
          disableMeal ||
          item.quantityAvailable ===
            getMealCount(
              mealsToAdd,
              spec.specificationCode || '',
              new Date(item?.startTime as string).getTime(),
              item.code,
            )
            ? true
            : false
        }
        inputAsText={
          mealsToAdd?.find(
            (e) =>
              e?.code !== item.code &&
              e.itemType === item.itemType &&
              new Date(item.startTime as string).getDay() === new Date(e?.startTime as Date).getDay(),
          ) === undefined
            ? 0
            : maxToBook
        }
        max={
          mealsToAdd?.find(
            (e) =>
              e?.code !== item.code &&
              e.itemType === item.itemType &&
              new Date(item.startTime as string).getDay() === new Date(e?.startTime as Date).getDay(),
          ) !== undefined
            ? 0
            : maxToBook
        }
        min={0}
        value={
          mealsToAdd?.find(
            (m) =>
              new Date(m.startTime).getTime() === new Date(item.startTime as string).getTime() &&
              m.subCode === spec.specificationCode &&
              m.legCode === item.legCode,
          )?.quantity || 0
        }
        setValue={(value) => {
          addMealsToCart(item as MealsDetailsFragment, value, spec, bookingCode || '', isOutbound)
        }}
        price={
          spec.hidePrice
            ? undefined
            : {
                amount: spec.specificationPrice.value,
                currency: spec.specificationPrice.currency,
                locale: i18n.language,
              }
        }
      />
    </Column>
  )
}
