import React, { JSX } from 'react'
import { useTranslation } from 'react-i18next'
import { AlertBanner, FormElementWrapper } from '@fjordline/styles-v3'

export default function NotEditableBanner({ isVisible }: { isVisible: boolean }): JSX.Element | null {
  const { t } = useTranslation()

  if (!isVisible) {
    return null
  }
  return (
    <FormElementWrapper>
      <AlertBanner header="" type="info" closeBtnTitle={`${t('label.button.close')}`}>
        {t('label.travelFriend.notEditable')}
      </AlertBanner>
    </FormElementWrapper>
  )
}
