import React, { useState } from 'react'
import { trackError, trackEvent } from '../../providers/TelemetryProvider'
import {
  Button,
  Modal,
  DialogContent,
  ModalActions,
  RadioButton,
  Input,
  Label,
  useToastDispatcher,
  Feedback,
} from '@fjordline/styles-v3'
import { StyledTextArea } from './StyledTextArea'
import { useKeycloak } from '../../providers/KeycloakProvider'
import { logDev } from '../LogDev'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'
import { useIssueReporter } from './useIssueReporter'
import type { IssueType } from './useIssueReporter'
import { AxiosError, AxiosResponse } from 'axios'
import useBreakpoint from '../../hooks/useBreakpoint'

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: left;
`

const CharCounter = styled.p`
  margin: 0;
  text-align: right;
`

const StyledDialogContent = styled(DialogContent)`
  margin-top: 1rem;
`

const StyledModalActions = styled(ModalActions)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1rem;
`

const DESCRIPTION_MAX_CHAR = 280

type Props = {
  onClose: () => void
}

export const ReportIssueModal: React.FC<Props> = ({ onClose }) => {
  const { userEmail: kc_userEmail } = useKeycloak()
  const { i18n, t } = useTranslation()
  const { dispatchToast } = useToastDispatcher()
  const { IssueType, IssueTypeDescription, submitIssue, generateIssueID } = useIssueReporter()
  const { Breakpoints, breakpoint } = useBreakpoint()

  const [issue, setIssue] = useState<IssueType>(IssueType.MissingBookings)
  const [bookingCode, setBookingCode] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [descriptionCharCount, setdescriptionCharCount] = useState<number>(0)
  const [isLoading, setIsLoading] = useState(false)
  const [validationError, setValidationError] = useState(false)
  const [submitError, setSubmitError] = useState(false)
  const [hasBookingCodeError, setHasBookingCodeError] = useState(false)

  const validateBookingCode = (value: string) => {
    setBookingCode(value)

    // 8 digit number
    if (/^\d{8}$/.test(value) || !value) {
      setHasBookingCodeError(false)
    } else {
      setHasBookingCodeError(true)
    }
  }

  const handleError = (response: AxiosResponse | false = false, error: unknown = false) => {
    setSubmitError(true)
    logDev('An error occured submitting the report', response, error)

    if (response) {
      trackEvent('User tried to report an issue but failed', { response: response })
    }

    if (error instanceof AxiosError) {
      trackError(error, { location: 'ReportIssueModal' })
    } else {
      trackError(new Error('Unknown error while attempting to POST issue'), { location: 'ReportIssueModal' })
    }
  }

  const handleSubmit = async () => {
    if (!issue || (issue === IssueType.Other && !description)) {
      setValidationError(true)
      return
    }

    setValidationError(false)
    setSubmitError(false)
    setIsLoading(true)

    try {
      const ids = await generateIssueID()

      const response = await submitIssue({
        email: kc_userEmail || '',
        bookingCode: bookingCode,
        issue: issue,
        description: description,
        language: i18n.language,
        id: ids.guid,
        shortID: ids.shortId,
      })

      if (response?.status === 202) {
        const customProperties = {
          reportID: ids.shortId,
          'Problem Type': IssueTypeDescription[issue],
          Message: description,
          ...(bookingCode && { 'Booking code(s)': bookingCode }),
        }

        trackEvent(`[User report] - ${issue}`, customProperties)

        dispatchToast({
          message: t('component.reportIssue.feedback.success'),
        })
        onClose()
      } else {
        handleError(response)
      }
    } catch (error: unknown) {
      handleError(false, error)
    } finally {
      setIsLoading(false)
    }
  }

  const onTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => setIssue(event.target.value as IssueType)

  const handleTextAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(event.target.value)
    setdescriptionCharCount(event.target.value.length)
  }

  const isSubmitDisabled =
    (issue !== IssueType.Other && !bookingCode) ||
    (issue === IssueType.Other && !description) ||
    isLoading ||
    hasBookingCodeError

  return (
    <Modal
      dialog={breakpoint !== Breakpoints.Small}
      label={t('component.reportIssue.modalTitle')}
      onRequestClose={onClose}
      ingress={t('component.reportIssue.modalIngress')}
    >
      <StyledDialogContent>
        <InputWrapper>
          <Label label={t('component.reportIssue.fieldLabels.issueType')} size="medium" />
          <RadioButton
            label={IssueTypeDescription[IssueType.MissingBookings]}
            name="issue"
            value={IssueType.MissingBookings}
            onChange={onTypeChange}
            disabled={isLoading}
            checked={issue === IssueType.MissingBookings}
            data-cy="reportIssueTypeMissingBookings"
          />
          <RadioButton
            label={IssueTypeDescription[IssueType.BookingDetails]}
            name="issue"
            value={IssueType.BookingDetails}
            onChange={onTypeChange}
            disabled={isLoading}
            checked={issue === IssueType.BookingDetails}
            data-cy="reportIssueTypeBookingDetails"
          />
          <RadioButton
            label={IssueTypeDescription[IssueType.Other]}
            name="issue"
            value={IssueType.Other}
            onChange={onTypeChange}
            disabled={isLoading}
            checked={issue === IssueType.Other}
            data-cy="reportIssueTypeOther"
          />

          <Input
            label={t('component.reportIssue.fieldLabels.bookingCode')}
            value={bookingCode}
            onChange={(event) => validateBookingCode(event.target.value)}
            state={hasBookingCodeError ? 'error' : 'default'}
            errorMessage={t('component.reportIssue.feedback.invalidBookingCode')}
            disabled={isLoading}
            data-cy="reportIssueBookingCode"
          />

          {issue && (
            <div>
              <Label
                required={issue === IssueType.Other}
                label={t('component.reportIssue.fieldLabels.description')}
                size="medium"
              >
                <StyledTextArea
                  required={issue === IssueType.Other}
                  rows={5}
                  spellCheck={true}
                  maxLength={DESCRIPTION_MAX_CHAR}
                  onChange={handleTextAreaChange}
                  value={description}
                  disabled={isLoading}
                  data-cy="reportIssueMessage"
                ></StyledTextArea>
              </Label>
              <CharCounter>
                {descriptionCharCount}/{DESCRIPTION_MAX_CHAR}
              </CharCounter>
            </div>
          )}

          {(validationError || submitError) && (
            <Feedback variant="notification" type="error">
              {validationError
                ? t('component.reportIssue.feedback.missingField')
                : t('component.reportIssue.feedback.error')}
            </Feedback>
          )}
        </InputWrapper>
      </StyledDialogContent>
      <StyledModalActions>
        <Button size="large" onClick={handleSubmit} disabled={isSubmitDisabled} data-cy="reportIssueSubmitButton">
          {t('component.reportIssue.button.submitReport')}
        </Button>
        <Button size="large" theme="ghost" onClick={() => onClose()} data-cy="reportIssueCancelButton">
          {t('component.reportIssue.button.cancel')}
        </Button>
      </StyledModalActions>
    </Modal>
  )
}
