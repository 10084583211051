// useQueryParams.ts
import { useNavigate, useLocation, Location } from 'react-router-dom'

export enum QueryParamsEnum {
  isUpdatingPassengers = 'isUpdatingPassenger',
  isUpdatingRegNum = 'isUpdatingRegNum',
  isUpdating = 'isUpdating',
}

const useQueryParams = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const setQueryParam = (key: string, value: string) => {
    const params = new URLSearchParams(location.search)
    params.set(key, value)
    navigate(
      {
        pathname: location.pathname,
        search: params.toString(),
      },
      { replace: true },
    )
  }

  const deleteQueryParam = (key: QueryParamsEnum, location: Location) => {
    const params = new URLSearchParams(location.search)
    params.delete(key)
    navigate(
      {
        pathname: location.pathname,
        search: params.toString(),
      },
      { replace: true },
    )
  }

  return { setQueryParam, deleteQueryParam }
}

export default useQueryParams
