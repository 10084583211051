import React, { JSX, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { DepartureDate, DestinationHeader, FormElementWrapper, InputText, Label } from '@fjordline/styles-v3'
import { QRCodeSVG } from 'qrcode.react'

import { Journey } from '../../graphql/types'
import { useTimeZonedDateFormatter } from '../../hooks/DateFormat'
import { useAvailabilityItems } from '../../providers/AvailabilityItemsProvider'
import { MayBe } from '../../types/MayBe'
import { findSumTotalBasket } from '../bookingList/BookingCards'

import BasketInfo from './boardingCards/BasketInfo'
import { BookingDetailsBasketInfoWrapper } from './boardingCards/basketInfo/basketInfo.styles'
import {
  BookingInfoDetailsWrapper,
  BookingInfoHeaderWrapper,
  BookingNumber,
  QrWrapper,
  TravelWay,
} from './sectionJourneyDetails/sectionJourneyDetails.styles'

/**
 * @description - Render the qr code and information about the outbound and inbound journey
 * @param inbound - inbound journey if any
 * @param outbound - outbound journey if any
 * @param bookingCode - for the qr code
 * @constructor
 */
export default function SectionJourneyDetails({
  backTargetTitle,
  inbound,
  outbound,
  bookingCode,
}: {
  backTargetTitle?: string
  inbound: Journey | undefined
  outbound: Journey | undefined
  bookingCode: string
}): JSX.Element {
  const d = useTimeZonedDateFormatter()
  const { t } = useTranslation()
  const outboundDestinationPort: string = useMemo<string>(
    () => (outbound ? t(`ports.${outbound.arrivalPortInfo?.carresPortCode}`) : ''),
    [outbound, t],
  )

  const addToCartState = useAvailabilityItems().addToCartState

  return (
    <>
      <FormElementWrapper dataCy="section-journeyDetails">
        <DestinationHeader
          title={outboundDestinationPort}
          imageUrl={MayBe.orElse<string>(
            outbound?.arrivalPortInfo?.imageUrl ?? null,
            'https://cdn.sanity.io/images/90kslno9/homeport/2602c6720afce8e9fa7fb6b0eecf8f3bbc49e6b3-1000x700.jpg?auto=format',
          )}
        >
          {findSumTotalBasket(bookingCode, addToCartState) > 0 ? (
            <BookingDetailsBasketInfoWrapper>
              <BasketInfo
                sumTotal={findSumTotalBasket(bookingCode, addToCartState)}
                bookingCode={bookingCode || ''}
                backTargetTitle={backTargetTitle}
              />
            </BookingDetailsBasketInfoWrapper>
          ) : null}
        </DestinationHeader>
      </FormElementWrapper>
      <BookingInfoHeaderWrapper>
        <QrWrapper>
          <QRCodeSVG size={100} value={bookingCode} />
        </QrWrapper>
        <BookingInfoDetailsWrapper>
          <div>
            <Label as="h5">{t('label.booking.code')}</Label>
            <BookingNumber>{bookingCode}</BookingNumber>
          </div>
        </BookingInfoDetailsWrapper>
      </BookingInfoHeaderWrapper>
      {outbound !== undefined ? (
        <TravelWay>
          <DepartureDate width={30} height={30} />
          <div>
            <Label as="h6">
              {outbound.departurePortInfo?.portName} - {outbound.arrivalPortInfo?.portName}
            </Label>
            <InputText>{d(outbound.departureDate, 'PPPPp')}</InputText>
          </div>
        </TravelWay>
      ) : // TODO: Render checkin times here
      null}
      {inbound !== undefined ? (
        <TravelWay>
          <DepartureDate width={30} height={30} />
          <div>
            <Label as="h6">
              {inbound.departurePortInfo?.portName} - {inbound.arrivalPortInfo?.portName}
            </Label>
            <InputText>{d(inbound.departureDate, 'PPPPp')}</InputText>
          </div>
        </TravelWay>
      ) : // TODO: Render checkint times here
      null}
    </>
  )
}
