import React, { JSX } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Button, FormElementWrapper } from '@fjordline/styles-v3'

import { ButtonFull } from '../../components/globalStyles'
import { HOURS_BEFORE_JOURNEY_START_TO_SHOW_BOARDING_CARD } from '../../config'
import { BoardingCardsNavLink } from '../../routes/navLinkFunctions'

import BoardingCardAvailabilityFeedback from './boardingCardLinkOrMessage/BoardingCardAvailabilityFeedback'
import isLessThanGivenHoursUntil from './boardingCardLinkOrMessage/isLessThanGivenHoursUntil'

export default function BoardingCardLinkOrMessage({
  backTarget,
  backTargetTitle,
  bookingCode,
  departureDate,
  isActiveOrFuture,
}: {
  backTarget: string | undefined
  backTargetTitle?: string
  bookingCode: string
  departureDate: string | undefined
  isActiveOrFuture: boolean
}): JSX.Element | null {
  const { t } = useTranslation()
  const hoursBeforeJourneyStartToShowBoardingCard = HOURS_BEFORE_JOURNEY_START_TO_SHOW_BOARDING_CARD || 48

  if (!isActiveOrFuture) {
    return null
  }

  return (
    <FormElementWrapper>
      <ButtonFull>
        {isLessThanGivenHoursUntil({
          departureDate,
          givenHours: hoursBeforeJourneyStartToShowBoardingCard,
        }) ? (
          <Link
            to={BoardingCardsNavLink({
              bookingCode: bookingCode,
              backTargetTitle,
              backTarget,
            })}
          >
            <Button theme="primary" size="medium">
              {t('component.boardingCards.button')}
            </Button>
          </Link>
        ) : (
          <BoardingCardAvailabilityFeedback
            hoursBeforeJourneyStartToShowBoardingCard={hoursBeforeJourneyStartToShowBoardingCard}
            t={t}
          />
        )}
      </ButtonFull>
    </FormElementWrapper>
  )
}
