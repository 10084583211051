import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { Feedback, H1, LottieLoader } from '@fjordline/styles-v3'

import { useCheckIfActiveMemberQuery, useGetBookingsQuery } from '../graphql'
import useAnchor from '../hooks/useAnchors'
import { paramKeys } from '../routes/navLinkfunctions/types'
import { useMyPage } from '../providers/myPageStateProvider/context'
import { useWebSocketContext } from '../providers/myPageStateProvider/websocketProvider/websocketContext'
import { BookingListWithKeys, defaultBookingListWithKeys } from '../types/MyPage'
import { Status } from '../types/myPage/enums'

import ActiveAndFutureLinksAndMessages from './bookingList/ActiveAndFutureLinksAndMessages'
import BecomeMemberBanner from './bookingList/BecomeMemberBanner'
import BookingCards from './bookingList/BookingCards'
import {
  getActiveJourneysCountAndMessage,
  getFutureJourneysCountAndMessage,
  JourneysCountAndMessage,
} from './bookingList/journeysCountAndMessage'
import NavigationButtons from './bookingList/NavigationButtons'
import OrderNextJourneyBannerOrButton from './bookingList/OrderNextJourneyBanner'
import setBookingListBasedOnViewStatus from './bookingList/setBookingListBasedOnViewStatus'
import { useKeycloak } from '../providers/KeycloakProvider'
import { useAvailabilityItems } from '../providers/AvailabilityItemsProvider'

import { ReportIssueButton, ReportButtonWrapper } from '../components/ReportIssue'
import { ErrorPage } from './booking/ErrorPage'

const BookingList: React.FC = () => {
  const { pathname } = useLocation()
  const [params, setParams] = useSearchParams()
  const { bookingList } = useMyPage()
  const { hasActivePayment } = useAvailabilityItems()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { hasUpdatedBooking } = useWebSocketContext()
  const { isAuthenticated: kc_isAUth, token, isLoading: kc_isLoading } = useKeycloak()
  const isLoading = kc_isLoading
  const hasTokenKcToken = token && token.length > 0
  const hasToken = hasTokenKcToken ? true : false

  const { data: isActiveMember, loading: isActiveMemberLoading } = useCheckIfActiveMemberQuery({
    skip: isLoading || !hasToken,
  })

  const isAuthenticated = kc_isAUth
  const { refetch, loading, error: getBookingsError } = useGetBookingsQuery({
    skip: !isAuthenticated,
  })

  useEffect(() => {
    if (
      sessionStorage.getItem('register_fc') === 'true' &&
      isAuthenticated &&
      !isActiveMemberLoading &&
      isActiveMember?.isActiveFjordClubMember === false
    ) {
      navigate('/fjordClub/signupAdd')
    }
  }, [isActiveMember?.isActiveFjordClubMember, isActiveMemberLoading, isAuthenticated, navigate])

  useEffect(() => {
    if (hasUpdatedBooking) {
      refetch()
    }
  }, [hasUpdatedBooking, refetch])

  useAnchor({ currentPath: pathname })
  const defaultListAndStatusToView = useMemo<{ bookingListToView: BookingListWithKeys; statusToView: Status }>(() => {
    if (
      bookingList &&
      ((bookingList.ongoing && Object.keys(bookingList.ongoing.indexedBookings).length === 0) ||
        (bookingList.future && Object.keys(bookingList.future.indexedBookings).length > 0))
    ) {
      return {
        bookingListToView: bookingList.ongoing,
        statusToView: Status.FUTURE,
      }
    }
    return {
      bookingListToView: bookingList ? bookingList.future : defaultBookingListWithKeys,
      statusToView: Status.FUTURE,
    }
  }, [bookingList])

  const statusToViewFromParam = useMemo(() => params.get(paramKeys.BOOKING_LIST_VIEW), [params])
  const getCurrentBookingStatusToView = sessionStorage.getItem('CurrentBookingStatusToView')
  const [currentBookingStatusToView, setCurrentBookingStatusToView] = useState<Status>(
    (getCurrentBookingStatusToView as Status) || defaultListAndStatusToView.statusToView,
  )
  const [currentBookingListToView, setCurrentBookingListToView] = useState<BookingListWithKeys>(
    defaultListAndStatusToView.bookingListToView,
  )

  const activeJourneysCountAndMessage = useMemo<JourneysCountAndMessage>(() => {
    return bookingList?.ongoing
      ? getActiveJourneysCountAndMessage({ bookings: bookingList.ongoing.indexedBookings, t })
      : { journeyCount: 0, journeysMessage: t('label.booking.noActiveBookings') }
  }, [bookingList?.ongoing, t])
  const futureJourneysCountAndMessage = useMemo<JourneysCountAndMessage>(() => {
    return bookingList?.future
      ? getFutureJourneysCountAndMessage({ bookings: bookingList.future.indexedBookings, t })
      : { journeyCount: 0, journeysMessage: t('label.booking.noBookings') }
  }, [bookingList?.future, t])

  useEffect(() => {
    setBookingListBasedOnViewStatus({
      statusToView: currentBookingStatusToView,
      setCurrentBookingListToView,
      bookingList,
    }).then(() => {
      window.scrollTo(0, 0)
    })
  }, [bookingList, currentBookingStatusToView])

  useEffect(
    function changeListOfOrderTypeParamInUrlChanges() {
      if (statusToViewFromParam !== null) {
        const nextStatusToView = Status[statusToViewFromParam] || Status.FUTURE
        setCurrentBookingStatusToView(nextStatusToView)
        sessionStorage.setItem('CurrentBookingStatusToView', nextStatusToView)
        setParams('')
      }
    },
    [setParams, statusToViewFromParam],
  )

  const showOrderNextJourneyBanner = useMemo(() => {
    return (
      (currentBookingStatusToView === Status.ACTIVE && activeJourneysCountAndMessage.journeyCount === 0) ||
      (currentBookingStatusToView === Status.FUTURE && futureJourneysCountAndMessage.journeyCount === 0) ||
      (currentBookingStatusToView === Status.PASSED &&
        bookingList !== undefined &&
        Object.keys(bookingList.passed.indexedBookings).length === 0) ||
      (currentBookingStatusToView === Status.CANCELLED &&
        bookingList !== undefined &&
        Object.keys(bookingList.cancelled.indexedBookings).length === 0)
    )
  }, [
    activeJourneysCountAndMessage.journeyCount,
    bookingList,
    currentBookingStatusToView,
    futureJourneysCountAndMessage.journeyCount,
  ])

  if (loading) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100vh' }}>
        <H1>{t('label.booking.header')}</H1>
        <LottieLoader />
      </div>
    )
  }

  if (!loading && getBookingsError) {
    return (
      <ErrorPage type='bookingList' />
    )
  }

  return (
    <div style={{ maxWidth: '50rem' }}>
      <H1>{t('label.booking.header')}</H1>{' '}
      {hasActivePayment && (
        <div style={{ marginBottom: '1rem' }}>
          <Feedback type="info" variant="notification">
            {t('component.extras.basket.alreadyReservedInCart_description')} ({hasActivePayment.bookingCode}) <br />{' '}
            <span
              onClick={() => navigate(`/bookingList/${hasActivePayment.bookingCode}/basket`)}
              style={{ textDecoration: 'underline', fontWeight: 'bold', cursor: 'pointer' }}
            >
              {t('label.button.gotoBasket')}
            </span>
          </Feedback>
        </div>
      )}
      <NavigationButtons
        bookingList={bookingList}
        currentBookingStatusToView={currentBookingStatusToView}
        setParams={setParams}
      />
      <ActiveAndFutureLinksAndMessages
        activeJourneysCountAndMessage={activeJourneysCountAndMessage}
        futureJourneysCountAndMessage={futureJourneysCountAndMessage}
        currentBookingStatusToView={currentBookingStatusToView}
        setParams={setParams}
      />
      {currentBookingListToView && (
        <BookingCards bookings={currentBookingListToView} status={currentBookingStatusToView} />
      )}
      <OrderNextJourneyBannerOrButton showBanner={showOrderNextJourneyBanner} />
      <BecomeMemberBanner />
      <ReportButtonWrapper>
        <ReportIssueButton />
      </ReportButtonWrapper>
    </div>
  )
}

export default BookingList
