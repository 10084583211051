import { useEffect, useState } from 'react'
import { Location, NavigateFunction, useLocation, useNavigate, useParams } from 'react-router-dom'
import { AxiosError } from 'axios'

import { logDev } from '../../../../components/LogDev'
import { getModifyBookingClient } from '../../../../rest/clients'
import { trackEvent } from '../../../../providers/TelemetryProvider'
import { useKeycloak } from '../../../../providers/KeycloakProvider'
import useQueryParams, { QueryParamsEnum } from '../../../../hooks/useQueryParams'
import { useBookingDetails } from '../../../../providers/BookingDetailsProvider'

function UpdateVehicles() {
  const location = useLocation()
  const { bookingCode, rowNumber } = useParams()
  const [loadingUpdateVehicles, setLoadingUpdateVehicles] = useState(false)
  const [error, setError] = useState<AxiosError | undefined>(undefined)
  const [success, setSuccess] = useState(false)
  const { token, isAuthenticated: kc_isAuth, isLoading: kc_isLoading } = useKeycloak()
  const isAuthenticated = kc_isAuth
  const [accessToken, setAccessToken] = useState<string>('')
  const { setQueryParam, deleteQueryParam } = useQueryParams()
  const { flBooking } = useBookingDetails()

  useEffect(() => {
    if (!kc_isLoading && kc_isAuth && token) {
      setAccessToken(token)
    }
  }, [kc_isAuth, kc_isLoading, token])

  const rowNumberQueryParam = rowNumber?.toString() || ''

  function updateVehicles(vehicles) {
    setQueryParam(QueryParamsEnum.isUpdatingRegNum, rowNumberQueryParam)

    setLoadingUpdateVehicles(true)
    if (bookingCode && accessToken && isAuthenticated) {
      getModifyBookingClient(accessToken)
        ?.updateVehicles(bookingCode, vehicles)
        .then(() => {
          trackEvent('user updated vehicles registration number')
          setSuccess(true)
          setError(undefined)
        })
        .catch((error) => {
          // Handle error
          setError(error)
          setSuccess(false)
          logDev(error)
          trackEvent('user failed to update vehicles registration number')
          deleteQueryParam(QueryParamsEnum.isUpdatingRegNum, location)
        })
        .finally(() => {
          setLoadingUpdateVehicles(false)
        })
    }
  }

  return {
    updateVehicles,
    loadingUpdateVehicles,
    error,
    success,
  }
}

export default UpdateVehicles
