import { useState, useEffect, useRef } from 'react'

// Based on @fjordline/styles-v3 media queries
interface Breakpoint {
  name: string
  width: number
}

const Breakpoints: { [key: string]: Breakpoint } = {
  Small: { name: 'Small', width: 768 },
  Medium: { name: 'Medium', width: 992 },
  Large: { name: 'Large', width: 1200 },
  XLarge: { name: 'XLarge', width: 1450 },
  Giant: { name: 'Giant', width: 1600 },
}

const sortedBreakpoints = Object.values(Breakpoints).sort((a, b) => a.width - b.width)

const getBreakpoint = (width: number): Breakpoint => {
  for (const breakpoint of sortedBreakpoints) {
    if (width < breakpoint.width) {
      return breakpoint
    }
  }

  return sortedBreakpoints[sortedBreakpoints.length - 1]
}

const useBreakpoint = () => {
  const [breakpoint, setBreakpoint] = useState(getBreakpoint(window.innerWidth))
  const breakpointRef = useRef(breakpoint)

  useEffect(() => {
    breakpointRef.current = breakpoint
  }, [breakpoint])

  useEffect(() => {
    const handleResize = () => {
      const newBreakpoint = getBreakpoint(window.innerWidth)
      if (newBreakpoint !== breakpointRef.current) {
        setBreakpoint(newBreakpoint)
      }
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return {
    breakpoint,
    Breakpoints,
  }
}

export default useBreakpoint
