import React, { JSX, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import {
  Feedback,
  FormElementWrapper,
  H1,
  H3,
  PaginatedCarousel,
  PaginatedCarouselItem,
  Spinner,
} from '@fjordline/styles-v3'
import { QRCodeSVG } from 'qrcode.react'

import CustomBackButton from '../../components/CustomBackbutton'
import { HOURS_BEFORE_JOURNEY_START_TO_SHOW_BOARDING_CARD } from '../../config'
import useGetBoardingCards from '../../graphql/customerHooks/UseGetBoardingCards'
import { BookingNavLink } from '../../routes/navLinkFunctions'

import BoardingCardAvailabilityFeedback from './boardingCardLinkOrMessage/BoardingCardAvailabilityFeedback'
import isLessThanGivenHoursUntil from './boardingCardLinkOrMessage/isLessThanGivenHoursUntil'
import { BoardingCard, CarouselContainer, MarginLessH3, MarginLessParagraph } from './boardingCards/boardingCards.style'
import firstAndLastName from './boardingCards/firstAndLastName'
import getCarouselItem from './boardingCards/getCarouselItem'

export type BoardingCardData = { [key: string]: string }
const BoardingCards: React.FC = () => {
  useMemo(() => window.scrollTo(0, 0), [])
  const { t } = useTranslation()
  const { bookingCode } = useParams()

  const {
    boardingCards,
    boardingCardsLoading,
    departureDate,
    departurePorts,
    getBoardingCardsError,
    isActiveOrFuture,
    isPassedOrCancelled,
  } = useGetBoardingCards({
    bookingCode,
  })
  const hoursBeforeJourneyStartToShowBoardingCard = useMemo(
    () => HOURS_BEFORE_JOURNEY_START_TO_SHOW_BOARDING_CARD || 48,
    [],
  )

  const [currentBoardingCardSlide, setCurrentBoardingCardSlide] = useState(0)
  const firstAndLastnameOfPassenger = useMemo(() => {
    const boardingCard = boardingCards?.[currentBoardingCardSlide]
    return boardingCard ? firstAndLastName(boardingCard) : ''
  }, [boardingCards, currentBoardingCardSlide])
  const carouselItems = useMemo<PaginatedCarouselItem<BoardingCardData>[]>(
    () => getCarouselItem(boardingCards),
    [boardingCards],
  )

  const bookingInfo: JSX.Element = useMemo(() => {
    return (
      <>
        <MarginLessH3>{t('label.booking.code')}</MarginLessH3>
        <MarginLessParagraph>{bookingCode}</MarginLessParagraph>
      </>
    )
  }, [bookingCode, t])

  const headerWithBackButton: JSX.Element = useMemo(() => {
    return (
      <>
        <FormElementWrapper>
          <CustomBackButton title={departurePorts} defaultBackLinkTo={BookingNavLink({ bookingCode: bookingCode })} />
        </FormElementWrapper>
        <H1>{t('component.boardingCards.header')}</H1>
        {boardingCardsLoading ? (
          <div>
            {t('component.general.loading')} {t('component.boardingCards.header')}... <Spinner />
          </div>
        ) : (
          ''
        )}
      </>
    )
  }, [boardingCardsLoading, bookingCode, departurePorts, t])

  // Due to many options, return is done using if-statements instead of ternary operator
  // 1. Show header and loading if loading
  if (boardingCardsLoading) {
    return <> {headerWithBackButton}</>
  }
  // 2. Show header and errormessage message if error
  if ((getBoardingCardsError && !boardingCardsLoading) || (boardingCards.length === 0 && !boardingCardsLoading)) {
    return (
      <>
        {headerWithBackButton}
        <Feedback type="error" variant="notification">
          {t('component.boardingCards.errorMessage')}
        </Feedback>
      </>
    )
  }
  // 3. Show header and message if booking is passed or cancelled
  if (isPassedOrCancelled || !isActiveOrFuture) {
    return (
      <>
        {headerWithBackButton}
        <Feedback
          type="info"
          variant="notification"
          title={`${t('component.boardingCards.messageBookingPassedOrCancelledIngress')}`}
        >
          {t('component.boardingCards.messageBookingPassedOrCancelled')}
        </Feedback>
      </>
    )
  }
  // 4.a. Show header and boarding card(s) if booking is active or future, and less than 48 hours before departure
  if (
    isActiveOrFuture &&
    isLessThanGivenHoursUntil({
      departureDate,
      givenHours: hoursBeforeJourneyStartToShowBoardingCard,
    })
  ) {
    return carouselItems.length === 0 ? (
      <>
        {headerWithBackButton}
        <BoardingCard>
          <QRCodeSVG size={180} value={carouselItems[0]?.data?.qrcode ?? ''} />
          <br />
          {bookingInfo}
        </BoardingCard>
      </>
    ) : (
      <>
        {headerWithBackButton}
        <CarouselContainer>
          <PaginatedCarousel
            arrows={true}
            arrowStatusText={firstAndLastnameOfPassenger}
            dropdown={true}
            dropdownPlaceholder={firstAndLastnameOfPassenger} //Adds name to dropdown instead of choose passenger
            currentSlide={currentBoardingCardSlide}
            onChange={(index) => setCurrentBoardingCardSlide(index)}
            items={carouselItems}
            renderItem={(item, data) => {
              return data ? (
                <BoardingCard>
                  <QRCodeSVG size={180} value={(data as BoardingCardData)?.qrcode} />
                  <br />
                  <H3>{item.title}</H3>
                  {bookingInfo}
                </BoardingCard>
              ) : (
                <span>{item.title}</span>
              )
            }}
          />
        </CarouselContainer>
      </>
    )
  } else {
    // 4.b. Show header and message if booking is active or future, but more than 48 hours before departure
    return (
      <>
        {headerWithBackButton}
        <BoardingCardAvailabilityFeedback
          hoursBeforeJourneyStartToShowBoardingCard={hoursBeforeJourneyStartToShowBoardingCard}
          t={t}
        />
      </>
    )
  }
}

export default BoardingCards
