import { JSX } from 'react'
import { QRCodeSVG } from 'qrcode.react'

import { Status } from '../../types/myPage/enums'
import React from 'react'

type QrCodeParams = (params: { status: Status; bookingCode: string }) => JSX.Element

const QrCode: QrCodeParams = (params) => {
  const { status, bookingCode } = params
  return status !== Status.CANCELLED ? <QRCodeSVG size={71} value={bookingCode} /> : <span />
}

export default QrCode
