import React, { JSX, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import {
  Add,
  Banner,
  BedDouble,
  Button,
  ChairComfort,
  Divider,
  FormElementWrapper,
  HeaderWithIcon,
  Label,
} from '@fjordline/styles-v3'
import styled from 'styled-components'

import { ButtonFull } from '../../components/globalStyles'
import { CDN_IMG_LOUNGE, CDN_IMG_UPGRADE_CABIN } from '../../config'
import UseGetInboundCabins from '../../graphql/availabilityHooks/cabins/UseGetInboundCabins'
import UseGetOutboundCabins from '../../graphql/availabilityHooks/cabins/UseGetOutboundCabins'
import { Cabin } from '../../graphql/types'
import { routerBookingSubPathsStrings } from '../../routes/appRoutes/routerPaths'
import { CabinsNavLink } from '../../routes/navLinkFunctions'

import Cabins from './sectionCabins/Cabins'
import { MinSideContainer } from './booking.styles'
import { useBookingDetails } from '../../providers/BookingDetailsProvider'
import { isPastDeadline } from '../../utils/isPastDeadline'

/**
 * @description - render booked cabins outbound and inbound else only the banner
 * @param bookingCode
 * @param inboundCabins
 * @param isActiveOrFuture
 * @param journeyPorts
 * @param outboundCabins
 * @constructor
 */
export default function SectionCabins({
  bookingCode,
  inboundCabins,
  journeyPorts,
  outboundCabins,
}: {
  bookingCode: string
  inboundCabins: Cabin[]
  journeyPorts: string
  outboundCabins: Cabin[]
}): JSX.Element | null {
  const { flBooking } = useBookingDetails()
  const modifyBookingDeadline = flBooking?.modificationDeadline

  const { t } = useTranslation()
  const hasCabins = useMemo<boolean>(
    () => inboundCabins.length > 0 || outboundCabins.length > 0,
    [inboundCabins.length, outboundCabins.length],
  )
  const hasInboundCabins = useMemo<boolean>(() => inboundCabins?.length > 0, [inboundCabins?.length])
  const hasOutboundCabins = useMemo<boolean>(() => outboundCabins?.length > 0, [outboundCabins?.length])

  const { data: outboundCabinsAvailable } = UseGetOutboundCabins(bookingCode)
  const { data: inboundCabinsAvailable } = UseGetInboundCabins(bookingCode)
  const hasOutboundCabinsAvailable =
    outboundCabinsAvailable?.cabinAvailability.availableCabins &&
    outboundCabinsAvailable?.cabinAvailability.availableCabins.length > 0
      ? true
      : false

  const hasOutboundSeatsAvailable =
    outboundCabinsAvailable?.cabinAvailability.availableSeats &&
    outboundCabinsAvailable?.cabinAvailability.availableSeats.length > 0
      ? true
      : false
  const hasInboundSeatsAvailable =
    inboundCabinsAvailable?.cabinAvailability.availableSeats &&
    inboundCabinsAvailable?.cabinAvailability.availableSeats.length > 0
      ? true
      : false
  const hasInboundCabinsAvailable =
    inboundCabinsAvailable?.cabinAvailability.availableCabins &&
    inboundCabinsAvailable?.cabinAvailability.availableCabins.length > 0
      ? true
      : false

  const hasNoCabins = !hasOutboundCabinsAvailable && !hasInboundCabinsAvailable
  const hasNoSeats = !hasOutboundSeatsAvailable && !hasInboundSeatsAvailable

  const noCabinOrSeats = hasNoCabins && hasNoSeats

  return (
    <div data-cy="section-cabins">
      <HeaderWithIcon icon={hasNoCabins && !hasNoSeats ? ChairComfort : BedDouble}>
        {hasNoCabins && !hasNoSeats ? t('component.extras.cabin.title_seat') : t('component.extras.cabin.title')}
      </HeaderWithIcon>
      {hasCabins ? (
        <MinSideContainer>
          <Label label={`${t('component.extras.common.departure')}`} />
          <Cabins cabins={outboundCabins} isOutbound={true} />
          {hasInboundCabins ? (
            <>
              {hasOutboundCabins && <Divider style={{ marginBottom: '1rem' }} />}
              <Label label={`${t('component.extras.common.arrival')}`} />
              <Cabins cabins={inboundCabins} isOutbound={false} />
            </>
          ) : null}
        </MinSideContainer>
      ) : null}
      {noCabinOrSeats || isPastDeadline({ modifyBookingDeadline }) ? null : (
        <>
          {' '}
          <NavLink
            to={CabinsNavLink({ bookingCode: bookingCode, backTargetTitle: journeyPorts })}
            key={routerBookingSubPathsStrings.EDIT_CABINS}
          >
            <FormElementWrapper>
              <BannerWrapper>
                <Banner
                  label=""
                  message={`${
                    hasNoCabins && !hasNoSeats
                      ? t('component.extras.cabin.addSeat')
                      : t('component.extras.cabin.upgradeCabin')
                  }`}
                  image={{
                    src: hasNoCabins && !hasNoSeats ? CDN_IMG_LOUNGE : CDN_IMG_UPGRADE_CABIN,
                    alt: `${t('component.extras.cabin.upgradeCabin')}`,
                  }}
                  enableClickEverywhere
                />
              </BannerWrapper>
            </FormElementWrapper>
            <ButtonFull>
              <Button dashedBorder theme="ghost" size="medium" leadingIcon={Add} dataCy="btn-section-add-cabins">
                {hasNoCabins && !hasNoSeats
                  ? t('component.extras.cabin.addSeat')
                  : t('component.extras.cabin.upgradeCabin')}
              </Button>
            </ButtonFull>
          </NavLink>
        </>
      )}
    </div>
  )
}

export const BannerWrapper = styled.div`
  span {
    color: white !important;
  }
  img {
    max-height: 300px;
    filter: brightness(70%);
  }
`
