import React, { JSX } from 'react'
import { Spinner } from '@fjordline/styles-v3'
import styled from 'styled-components'

const Container = styled.div`
  height: 100px;
`

const LoadingWave = ({
  hideSpinner = false,
  children,
}: {
  hideSpinner: boolean | undefined
  children: string | JSX.Element | undefined
}) => {
  return (
    <Container>
      {!hideSpinner ? <Spinner /> : null}
      {children}
    </Container>
  )
}

export default LoadingWave
