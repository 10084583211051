/* eslint-disable @typescript-eslint/naming-convention */
import { PartialTranslation } from './nb'
import CarRes from '../CarRes/en'

export const translation: PartialTranslation = {
  language: {
    da: 'Dansk',
    de: 'Deutsch',
    en: 'English',
    nb: 'Norsk',
  },
  country: {
    da: 'Denmark',
    de: 'Germany',
    en: 'England',
    nb: 'Norway',
    nl: 'Nederland',
  },
  number: {
    1: 'One',
    2: 'Two',
    3: 'Three',
    4: 'Four',
    5: 'Five',
    6: 'Six',
    7: 'Seven',
    8: 'Eight',
    9: 'Nine',
    10: 'Ten',
    11: 'Eleven',
    12: 'Twelve',
    13: 'Thirteen',
    14: 'Fourteen',
    15: 'Fifteen',
  },
  bookingListStatus: {
    journeys: 'journeys',
    ACTIVE_AND_FUTURE: 'Active and future $t(bookingListStatus.journeys)',
    CANCELLED: 'Cancelled $t(bookingListStatus.journeys)',
    PASSED: 'Passed $t(bookingListStatus.journeys)',
    DEFAULT: '$t(bookingListStatus.ACTIVE_AND_FUTURE)',
  },
  component: {
    general: {
      loading: 'Loading',
      departure: 'Outbound journey',
      return: 'Return journey',
      updatingBooking: 'Updating booking details...',
      bookingIsUpdated: 'Booking {{bookingCode}} has been updated!',
    },
    welcome: {
      toMyPage: 'Welcome to My Page',
      oldMyPage: 'Are you experiencing problems with the new My Page?',
      goToOldMyPage: 'Go back to old My Page',
      generalInfo:
        'Here you will find an overview of your travels and / or your Fjord Club membership. If you are not yet a Fjord Club member, you can sign up here.',
    },

    auth: {
      renew: {
        headline: 'You need to renew your user',
        paragraph1: 'We have upgraded our login systems, and therefore, you need to renew your user. You can use the same email and password as before.',
        paragraph2: 'All original information and bookings will still be available.',
        renewBtn: 'Renew user',
      },

      verifyAndProceed: 'Verify and proceed',
      header: 'My page',
      login: 'Log in',
      logout: 'Log out',
      noCode: 'Not received password?',
      oneTimeCode: 'One-time password',
      email: 'Email',
      phone: 'Mobile number',
      resendCode: 'Send new one-time password',
      codeInstructions: 'Do not close this window. Open a new tab to retrieve the one-time password',
      loginError: 'Login failed, please try again.',
      facebookEmailRequired:
        'We need access to your email address for My Page to work. Please log in again and grant access to your email address or select another login option.',
      auth0Error: {
        access_denied: 'Incorrect one-time password. Make sure it is entered correctly and try again.',
      },
      multipleProfiled: {
        header: 'Your username',
        ingress1: 'There is more than one profile associated with your username ({{userName}})',
        ingress2:
          'We have registered a support case on the issue and will contact you as soon as the case is resolved or if we need more information from you',
        ingress3: 'Please contact the customer center so they can help you',
        phone: 'Phone number',
        email: 'E-mail',
        buttonText: 'View contact information',
      },
      emailNeedsVerification:
        'Before you can log in for the first time, we need to verify your email address. Enter the one-time code you have received in your e-mail.',
      emailVerified: 'Your email address has been verified and you can now log in.',
      signUp: 'Sign up',
    },

    cancelBooking: {
      info: 'Cancellation fees vary depending on ticket type and time of cancellation',
     description: 'Any refund, if applicable, will be calculated and issued according to the terms provided at the time of booking.',
     readMore: 'Read more about our cancellation policy here',
      cost: 'Cancellation of this trip costs',
      disclaimer: 'This action cannot be reversed.',
      dontCancel: 'I do not want to cancel. Go back',
      prompt: 'Are you sure you want to cancel your booking?',
      title: 'Cancel booking',
      notify: {
        loading: 'Cancellation in progress...',
        success: 'Cancellation done!',
        failure: 'Cancellation failed!',
      },
    },
    requestBookingModification: {
      button: 'Alter journey',
      header: 'Alter journey',
      content: 'Choose to be contacted to re-book the trip, or receive a voucher that can be used on future trips.',
      reArrange: 'Re-book',
      valueCheck: 'Voucher',
      success: 'Message sent, you will hear from us shortly.',
      error: 'Message not sent due to an error. Please try again later or contact customer service.',
    },
    extras: {
      button: 'Show additional products',
      noExtras: 'You have not yet ordered any additional products',
      currencyInfo: 'The currency displayed is the one you booked your journey with.',
      cabin: {
        title_seat: 'Seat',
        changeCabin: 'Change cabin',
        loungeError: 'Lounge is not available for selected passangers.',
        chooseCabins: 'Choose cabins',
        searchingForCabins: 'Searching for available cabins',
        passengersToPlace_plural: 'You must place {{count}} passengers before you can continue.',
        passengersToPlace_singular: 'You must place {{count}} passenger before you can continue',
        addCabin: 'Change/add cabin',
        addSeat: 'Add seat',
        selectedCabins: 'Current order:',
        newCabins: 'New order:',
        removeCabin: 'Remove',
        passengersToPlaceModal: 'You have to place {{count}} passengers',
        totalPrice: 'Total price',
        allPassengersPlaced: 'All passengers are placed!',
        lowerPriceWarning: 'The cabins you have chosen cost less than your previous cabin order.',
        title: 'Cabins',
        window: 'Windows',
        persons: 'Persons',
        price: 'Price',
        crib: 'Room for crib: ',
        goToBasketToast: 'All passengers are placed. Go to basket',
        cabinOutbound_basket: 'Cabin outbound',
        cabinInbound_basket: 'Cabin inbound',
        changeCabin_basket: 'Click here to change cabin.',
        addMissingCabin_basket: 'Add missing cabin.',
        allPassengersMustBePlaced_basket: 'All passengers must be placed before you can complete the order.',
        booked:
          'Reserved: $t(component.extras.cabin.qty, { \'count\': {{count}} }) including $t(component.extras.cabin.specQty, { \'count\': {{specQty}} })',
        booked_plural:
          'Reserved: $t(component.extras.cabin.qty, { \'count\': {{count}} }) including $t(component.extras.cabin.specQty, { \'count\': {{specQty}} }) each',
        booked_noseats: '$t(component.extras.cabin.specQty, { \'count\': {{specQty}} }) without specified seat',
        qty: '{{count}} cabin',
        qty_plural: '{{count}} cabins',
        specQty: '{{count}} passenger',
        specQty_plural: '{{count}} passengers',
        spec: CarRes.cabinSpec,
        noAvailability: 'No available cabins on this journey.',
        oldCabins_basket: 'Cabins that will be removed:',
        upgradeCabin: 'Upgrade cabin',
        quantityInCabin: 'Passengers',
        seeAll: 'See all cabins',
        priceUpgrade: 'Price upgrade',
        feedback: {
          toast: {
            minReq: 'You must select at least 1 person to add this cabin.',
            minOrd: 'This cabin has a minimum order of {{minOrd}} persons. You have {{passengersYetToPlace}} persons left to place.',
          },
          deleteBeforeAdd: 'Sorry, all passengers are already assigned seating. If you want to change the seating, you must first remove some passengers from the selected cabin or seat choice.',
          missingPassengersPlaced: 'You have placed {{passengersPlaced}} out of {{passengersToPlace}} passengers. All passengers must be placed to proceed to the shopping cart.',
          newPriceIsLessThanExistingPrice: 'Selected cabins cost less than the already ordered ones. Please select other or additional cabins so that the upgraded cabins exceed the price of the already ordered ones.',
          allPassengersPlaced: 'All passengers are placed!',
          basket: {
            allPassengersPlacedError: 'Not all passengers from {{errorType}} are placed. Please add the missing cabins/seats to proceed to payment, or remove them from the shopping cart.',
            goToCabins: 'Go to cabins',
            missingCabins: 'Missing cabins/seat',
            removeCabins: 'Remove cabins',
            existingRemoved:'Removed',
          }
        }

      },
      common: {
        arrival: 'Return trip',
        count: 'Count',
        departure: 'Departure',
        readMore: 'Read more',
        deleteHeader: {
          remove: 'Remove',
          itemGeneral: '$t(component.extras.common.deleteHeader.remove) product',
          cabins: '$t(component.extras.common.deleteHeader.remove) cabin',
          extrasAshore: '$t(component.extras.common.deleteHeader.remove) extra on shore',
          extrasOnboardInbound: '$t(component.extras.deleteHeader.common.remove) extra on board',
          extrasOnboardOutbound: '$t(component.extras.deleteHeader.common.remove) extra on board',
          meals: '$t(component.extras.common.deleteHeader.remove) meal',
          meetingRooms: '$t(component.extras.common.deleteHeader.remove) meeting room',
        },
        deleteItem: {
          itemGeneral: 'this product',
          cabins: 'this cabin',
          extrasAshore: 'this product',
          extrasOnboardInbound: '$t(component.extras.common.deleteItem.extrasAshore)',
          extrasOnboardOutbound: '$t(component.extras.common.deleteItem.extrasAshore)',
          meals: 'this meal',
          meetingRooms: 'this meeting room',
          deleteItemQuestion:
            'Do you want to remove $t(component.extras.common.deleteItem.{{extraCategory}}) from basket?',
        },
      },
      taxesAndFees: {
        headline: 'Taxes and fees',
        envTax: 'EU environmental surcharge',
      },
      meal: {
        add: 'Add meals',
        arrival: 'Return trip',
        count: 'Quantity',
        departure: 'Departure',
        existingOrder: 'Ordered and payed',
        qty: 'Number of reservations: {{count}} passenger',
        qty_plural: 'Number of reservations: {{count}} passengers',
        selectDay: 'Select day',
        spec: CarRes.mealSpec,
        spec_plural: CarRes.mealSpec,
        time: 'Time of reservation: {{time}}',
        title: 'Meals',
        totalPrice: 'Total amount: {{amount}}',
        yourMeals: 'Your meals',
        chooseTime: 'Choose time',
        nomeals: 'No reservations',
        meal: 'meal',
        meals: 'meals',
        fromPrice: 'From',
        buttonLabelCount: 'Select quantity',
        noDetailsAvailable: 'No details available at the moment',
        labelTime: 'Select time',
        searchingForMeals: 'Searching for available meals',
        deleteToastMessage: '{{mealName}} removed from basket',
        AddOneToastMessage: '{{specName}} added',
        RemoveOneToastMessage: '{{specName}} removed',
        loadingMeals: 'Loading available meals',
        inTotal: 'In total',
        noMealsAvailable: 'No meals available',
        adult: 'adult',
        adults: 'adults',
        child: 'children',
        baby: 'baby',
        infant: 'infant',
        sixcour: 'six-course',
        fivecour: 'five-course',
        fourcour: 'four-course',
        threecour: 'three-course',
        discount: 'Order now and get a 20% discount on all meals',
      },
      basket: {
        conditions: {
          ingress: 'I have read and understood',
          terms: 'the conditions',
          force: 'Please accept the conditions',
        },
        added: 'Added',
        sumMeal: 'Total meal',
        totalToPay: 'Total to pay',
        completeOrder: 'Complete order',
        cancellingOrder: 'The cart is being cleared...',
        availableSoon: 'coming soon...',
        receipt: 'Receipt',
        thankYou: 'Thank you for your order',
        payed: 'Payed',
        remainsToPay: 'Remains to pay',
        timeoutError: 'Something went wrong. Please try again',
        processingOrder: 'The order is being processed. Please wait...',
        deleteOrder: 'Empty cart and start over',
        retry: 'Retry',
        orderCancelled: 'Order cancelled',
        cancelOrder: 'Empty basket',
        paymentFailed: 'The payment could not be completed.',
        basketInfoErrorMessage: 'Sorry, something went wrong with the payment.',
        paymentErrorMessage:
          'It is likely that payment for these items has already been made. Return to My Travels and check the status of the trip.',
        goToPayment: 'Go to payment',
        orderIsReady: 'Your order is ready',
        showOrderAndPay: 'Show cart and pay',
        empty: 'The shopping cart is empty...',
        alreadyReservedInCart_description: 'You must remove or pay for reserved items before you can add more items. You can choose to proceed to payment, empty the cart, or view products in the cart',
        emptyCart: 'Empty cart',
        alreadyReservedInCart: 'You already have reserved items in the cart',
        seeCart: 'View cart',

      },
      misc: {
        title: 'Miscellaneous',
        other: 'Others',
        qty: '{{count}} stk',
        ashoreTitle: 'Extras ashore',
        onboardTitle: 'Extras onboard',
        tickets: 'View tickets',
        onShoreTickets: '{{count}} tickets',
        loadingExtraOnboard: 'Loading available additional products on board',
        loadingExtraAshore: 'Loading available additional products ashore',
        noExtrasAvailable: 'There are no available additional products for your journey',
        noExtrasAvailableOnboard: 'There are no available additional products onboard for your journey',
        noExtrasAvailableAshore: 'There are no available additional products ashore for your journey',
      },
      ashore: {
        title: 'Accommodation ashore',
        booked: 'Reserved: $t(component.extras.ashore.qty, { \'count\': {{count}} })',
        qty: '{{count}} ',
        qty_plural: '{{count}} ',
        checkin: 'Checkin',
        checkout: 'Checkout',
        roomType: 'Rooms',
      },
    },
    fjordClub: {
      header: 'Fjord Club',
      registered :'Your Fjord Club membership is registered',
      updated: 'Your Fjord Club membership has been updated',
      unsavedChanges: 'You have unsaved changes. Do you wish to save your modifications?',
      terms: {
        link: 'https://www.fjordline.com/en/p/fjord-club/terms-and-conditions',
        view: 'Terms and conditions',
      },
      consent:
        'I agree to Fjord Club\'s terms and conditions, including receiving email from Fjord Line, and that Fjord Line stores my personal and purchase information.',
      description:
        'Fjord Club is our way of appreciating those who enjoy traveling with Fjord Line. Join for free and you can earn points on your travels and receive exclusive offers and discounts.',
      delay:
        'NOTE: After you create the Fjord Club profile, it can take up to a day before the profile is automatically retrieved when you log in. The profile can always be retrieved manually by filling in the required fields and resubmitting the form.',
      join: 'Register your Fjord Club membership',
      join_description: 'Fjord Club is our way of appreciating you for traveling with Fjord Line. Join for free and earn points on your travels, plus receive exclusive offers and discounts.',
      minimumAge: 'You must be 18 years old to become a member of Fjord Club.',
      joinBannerMessage: 'Always benefits with Fjord Club - Join for free!',
      fcMemberCreateFailed: 'We are experiencing temporary issues with membership creation. Please try again later or contact our customer service for assistance if the problem persists.',
      fcMemberUpdateFailed: 'It looks like we are currently experiencing issues updating memberships. Please try again later or contact customer service if the problem persists.',
      mustVerifyMobileFeedback: 'You need to verify your mobile number to save.',
      manualVerificationMessage: 'Your phone number cannot receive a verification code automatically. Contact customer service to verify your phone number. Customer service will be able to create a code for you, which you enter in the field below.',
      contactCustomerService: 'Contact customer service',

    },
    boardingCards: {
      button: 'Open boarding pass',
      header: 'Boarding pass',
      messageNotAvailable:
        'Boarding passes will be available {{hoursBeforeJourneyStartToShowBoardingCard}} hours before departure',
      messageNotAvailableTitle: 'NOTE!',
      departure: 'departure',
      arrival: 'return trip',
      switchTo: 'Switch to {{journey}}',
    },
    footer: {
      contactUs: 'Contact us',
      followUs: 'Follow us',
    },
    editVehicles: {
      updated: 'Registration number is updated!',
      error: 'Something went wrong. Could not update. Please try again later or contact customer service if the problem persists.'
    },
    editPassengers: {
      updated: 'Passenger information has been updated!'
    },
    reportIssue: {
      button: {
        reportIssue: 'Report an issue',
        submitReport: 'Send report',
        cancel: 'Cancel',
      },
      modalTitle: 'Report an issue',
      modalIngress: "We are here to help! Let us know if you experience any issues, and we will work to resolve them as quickly as possible.",
      issueTypes: {
        missingBookings: 'Missing booking(s)',
        bookingDetails: 'Unable to view booking details',
        otherIssue: 'Other issue'
      },
      fieldLabels: {
        issueType: 'What type of issue are you experiencing?',
        bookingCode: 'Booking number (8-digit number)',
        description: 'Please describe the issue',
      },
      feedback: {
        success: 'Thank you! Your issue has been reported.',
        error: 'Something went wrong. If the problem persists, please contact us at info@fjordline.com',
        missingField: 'Please fill out the required fields.',
        invalidBookingCode: 'Please enter a valid booking number with 8 digits.'
      }
    }
  },
  metadata: {
    title: {
      booking: 'Booking {{bookingCode}}',
      bookings: 'My bookings',
      extras: 'Add-ons {{bookingCode}}',
      login: 'My Page Login',
      fjordClub: 'Fjord Club',
      accommodation: 'Accommodation',
      default: 'My Page - Fjord Line',
    },
    traveller: {
      ADL: 'Adults 16+',
      CHD: 'Children 4-15 years',
      INF: 'Infants',
      BABY: 'Baby 0-11 months',
    },
  },
  navbar: {
    bookings: '$t(metadata.title.bookings)',
    fjordClub: '$t(metadata.title.fjordClub)',
    login: '$t(component.auth.login)',
    logout: '$t(component.auth.logout)',
    menu: 'Menu',
  },
  addModal: {
    extrasOnboard: 'Extra onboard',
    extrasAshore: 'Extra ashore',
  },
  error: {
    reset:'Go back to My Page',
    somethingWrong: 'Something went wrong',
    timeout: {
      title: 'No response from the server',
      message:
        'It seems that it takes too long to get a response from the server. This could be due to either a bad connection or an error with our servers. Please try reloading the page at a later time.',
    },
    authorization: 'Authorization Error',
    authTimeOut: 'The authentication procedure took too long',
    booking: {
      cancellationFailed: 'Could not cancel booking',
      getFailed: 'Could not retrieve details for the trip. Please try to reload the page.',
      updateFailed: 'Failed to update trip',
      cancelled: 'The trip has been canceled',
      notFound: 'Could not find the trip',
      pdfFailed: 'Could not get travel confirmation',
      resendPdfFailed: 'Failed to send travel confirmation',
      regnumFailed: 'Failed to update registration number',
      travelerFailed: 'Failed to update passenger information',
      invalid: 'The journey you are trying to retrieve is invalid.',
      connectionError: 'Could not retrieve orders. Please try reloading',
      bookingListFailed: 'We encountered a problem while fetching your bookings. Please try again later.',
      detailsNotFound: 'We were unable to load the travel details for your trip',
      detailsNotFoundBookingCode: 'with booking code: ',
      problemPersists: 'If this problem persists, please contact us or use the Report an issue button to let us know.'
    },
    customer: {
      notFound: 'Could not find profile',
      getFailed: 'Could not retrieve profile',
      createFailed: 'Failed to create profile',
      createAlreadyExists: 'The profile already exists',
      updateFailed: 'Failed to update profile',
      travelFriends: {
        addFailed: 'Could not add travel companion',
        updateFailed: 'Failed to update travel companion',
        deleteFailed: 'Could not delete travel companion',
      },
    },
    notify: {
      loading: 'Change in progress...',
      success: 'Change confirmed!',
      failure: 'Change failed!',
    },
    response:
      'Unable to receive server response. \nPlease make sure you have a network connection and reload the page to try again',
    generic: 'Oops... something went wrong...',
    unchanged: 'The form did not contain any changes',
    unknown: 'Unknown error',
    gofish: 'Ohoi sailor... no journeys here...',
    basket: {
      generic: 'Something went wrong... Unable to complete the payment. Please try again later',
      or: 'or',
      contactCustomerService: 'contact customer service if the issue persists.',
      rollback: 'Could not empty the shopping cart. Please try again or contact customer service if the issue persists.',
      refused:
        'The payment was rejected. Check the card information and try again, or contact us if the problem persists.',
        refused_headline: 'Payment rejected',
        help: 'Need help? Contact customer service here',

    }

  },
  label: {
    allFieldsRequired: '* All fields must be filled out',
    booking: {
      header: 'My bookings',
      code: 'Booking number',
      resendPDF: 'Send travel documents by email',
      resendFailed: 'Sending failed',
      noBookings: 'You have no upcoming trips.',
      activeBooking_plural: 'You have {{count}} active trips',
      activeBooking: 'You have one active trip',
      upcomingBooking_plural: 'You have {{count}} upcoming trips',
      noActiveBookings: 'You have no active trips.',
      upcomingBooking: 'You have one upcoming trip',
      makeBooking: 'Book your next trip today',
      makeNewBooking: 'Book a new trip',
      active: 'Active',
      future: 'Upcoming',
      cancelled: 'Cancelled',
      unavailable: 'Unavailable',
      pastBooking: 'Travel completed',
      showCancelled: 'View cancelled',
      showPassed: 'View past trips',
      hidePassed: 'Hide past trips',
      hideCancelled: 'Hide cancelled',
      cancelValidTime: 'You can cancel the trip up to 24 hours before departure',
      paymentDone: 'Payment completed',
      paymentMessage: 'Thank you for your purchase! The products you have purchased will be visible in your booking overview.',
      basketInfo: {
        yourBasket: 'Your shopping cart',
        info: 'Go to the shopping cart to complete or delete the changes on the trip',
        toPay: 'To pay:',
      },
    },
    boolean: {
      false: 'No',
      true: 'Yes',
    },
    button: {
      add: 'Add',
      addMore: 'Add more',
      addMoreItems: 'Add items to the trip',
      noItems: 'No items available on this journey',
      addMoreExtraOnboard: 'Add extras onboard',
      addMoreExtraAshore: 'Add extras ashore',
      back: 'Back',
      backToBookings: 'Back to my bookings',
      cancel: 'Cancel',
      close: 'Close',
      closeAlert: 'Close warning',
      confirm: 'Confirm',
      delete: 'Delete',
      deselectAll: 'Remove all',
      edit: 'Edit',
      gotoBasket: 'Go to cart',
      join: 'Become a member for free!',
      loading: 'Loading...',
      moreInfo: 'Open travel details',
      resetChanges: 'Reset changes',
      save: 'Save',
      saveChanges: 'Save changes',
      selectAll: 'Select all',
      submit: 'Send',
      show: 'Show',
      ACTIVE_AND_FUTURE: 'Active and future',
      CANCELLED: 'Cancelled ',
      PASSED: 'Passed ',
      FUTURE: 'Future ',
      ACTIVE: 'Active ',
      bookingOverview: 'Go to booking overview',
      downloadPDF: 'Download travel confirmation (PDF)',
    },
    country: CarRes.country,
    currency: {
      NOK: '{{amount}} NOK',
      DKK: '{{amount}} DKK',
      EUR: '€{{amount}}',
    },
    date: {
      day: 'Day',
      month: 'Month',
      month_1: 'January',
      month_2: 'February',
      month_3: 'March',
      month_4: 'April',
      month_5: 'May',
      month_6: 'June',
      month_7: 'July',
      month_8: 'August',
      month_9: 'September',
      month_10: 'October',
      month_11: 'November',
      month_12: 'December',
      year: 'Year',
    },
    gender: {
      F: 'Female',
      M: 'Male',
    },
    journey: {
      arrival: 'Arrival',
      arrivalJourney: 'Return trip',
      arrivalPort: 'Arrival harbour',
      arrivalTime: 'Arrival time',
      departure: 'Departure',
      departureJourney: 'Departure',
      departurePort: 'Departure harbour',
      departureTime: 'Departure time',
      product: 'Ticket category',
      stretch: 'Stretch',
    },
    customer: {
      allowContact: 'Allow Fjord Line to contact me',
      countryCode: 'Country code',
      currency: 'Currency',
      customerCode: 'Customer Id',
      customerGroup: 'Your interests',
      language: 'Language',
      name: 'Name',
      travelFriend: 'Travel companion',
      consents: {
        header: 'Consent',
        pretext:
          'I agree to the Fjord Club Terms and Conditions and agree that Fjord Line stores my information for the following purposes:',
        posttext:
          'Fjord Line only uses the information to give you a better experience and they are not shared with any third party.',
        EMAIL: 'Send email, user profiling, purchase information',
        MAIL: 'Send me mail',
        SMS: 'Send me SMS',
      },
    },
    membership: {
      header: 'Membership Information',
      memberNumber: 'Member number',
      membershipLevel: 'Membership level',
      memberSince: 'Member since',
      loyaltyPointsEarned: 'Total points earned',
      loyaltyPointsBalance: 'Points',
      loyaltyPointsSoonExpiring: 'Points soon expiring',
    },
    signedUp:  {
      goToMyTravels: 'Go to My Travels',
      seeMembership : 'See membership',
    },
    personalia: {
      code: 'code',
      dateOfBirth: 'Date of birth',
      personalData: 'Personal information',
      email: 'E-mail',
      firstName: 'First name',
      gender: 'Gender',
      header: 'Personal information',
      lastName: 'Last name',
      mobile: 'Mobile number',
      mobileWord: 'text message',
      receiveNewVerificationCode: 'Receive new verification code',
      verificationCode: 'Confirmation code',
      verificationCompleted: '{{field}} has been verified',
      verificationModalMessage: 'Enter verification code received in {{field}}.',
      verificationRequired: 'Changed {{field}} must be confirmed',
      verify: 'Confirm {{value}}',
      verifyErrorMessage: 'Entered code can not be verified',
      tpoCannotSendCodeMessage: 'Could not send one-time code. Please try again.',
    },
    address: {
      header: 'Address',
      address: 'Address',
      zip: 'Zip',
      city: 'City',
      country: 'Country',
      nationality: 'Nationality',
    },
    traveler: {
      edit: 'Edit traveler',
      header: 'Travelers',
      disabledPerson: 'Disabled / need assistance',
      guestType: 'Ticket category',
      passportNumber: 'Passport number',
    },
    travelFriend: {
      tempDisabled: 'Travel companions will be available soon',
      header: 'Travelers',
      add: 'Add travel companion',
      delete: 'Delete travel companion',
      edit: 'Edit travel companion',
      find: 'Existing member',
      fill: 'Please fill in information',
      new: 'New travel companion',
      notEditable: 'This traveling companion is a member of the Fjord Club and cannot be changed.',
      birthDate: 'Date of birth',
      nationality: 'Nationality',
    },
    unknown: 'Ukjent',
    vehicle: {
      edit: 'Edit vehicle',
      header: 'Vehicle',
      category: 'Car type',
      length: 'Length',
      length_upto: 'Up to {{length}} m long',
      regNum: 'Registration number',
      code: {
        vehicle195: 'Vehicle up to 1.95 m high',
        vehicle220: 'Vehicle up to 2.20 m high',
        vehicle235: 'Vehicle up to 2.35 m high',
        vehicle450: 'Vehicle up to 4.50 m high',
        bik: 'Bike',
        mc: 'Motorcycle',
        mcs: 'Motorcycle with sidecar',
      }
    },
    preferences: {
      header: 'Travel',
      portOfDeparture: 'Preferred port of departure',
    },
    tickets: {
      header: 'Tickets',
      sendToEmail: 'Send to email',
      departureDate: 'Departure date:',
      resentTickets: 'The tickets have been sent to your email',
      resendTicketsFailed: 'We apologize, but we are unable to resend your tickets at the moment. Please try again later. If the problem persists, please contact customer service for assistance.',
      resendTimeout: 'Can be resent in {{seconds}} seconds',
      sendToEmailAgain: 'Send again',
      ticketError: 'We apologize, but we are unable to retrieve your tickets at the moment. Please try again later. If the problem persists, please contact customer service for assistance.',
      ticketError_delay: 'Please note that if the booking was just made, it may take a few minutes for the tickets to become available on this page.',

      barcode: {
        alt: 'barcode',
      },
    },
  },
  placeholder: {
    personalia: {
      firstName: 'Your first name',
      lastName: 'Your last name',
      gender: 'Select gender',
      email: 'Your email',
      mobile: 'Your phone number',
      mobileNoCC: 'Your phone number',
    },
    address: {
      address: 'Your address',
      zip: 'Your zip code',
      city: 'Your home city',
      country: 'Select country',
      nationality: 'Select nationality',
    },
    traveler: {
      passportNumber: 'Your passport number',
    },
    vehicle: {
      regNum: 'Your vehicle registration number',
    },
    preferences: {
      portOfDeparture: 'Please select preferred port of departure',
    },
  },
  validation: {
    isRequired: 'Mandatory information',
    memberNumber: 'Membership number must be 7 or 10 digits',
    regNumber: 'Registration number must be at least 2',
    isAlphabetic: 'This field should contain only letters',
    isAlphanumeric: 'This field should contain only letters and numbers',
    isDate: 'The selected date is not valid',
    isDateInFuture: 'The date chosen is not a valid date of birth',
    isGuestType: 'The date of birth does not match the age restrictions of the ticket',
    isEmail: 'This is not a valid email address',
    isMobile: 'This is not a valid phone number',
    isCountryCode: 'This is not a valid country code',
    isOneTimeCode: 'This is not a valid one-time password',
    isOwnCustomerCode: 'Can\'t use your own customer ID',
    isNumeric: 'This field should only contain numbers',
    isPostalCode: 'This is not a valid zip code',
    hasDigits: 'This field should not contain numbers',
    hasLetters: 'This field should not contain letters',
    hasCountryCode: 'Country code is required (+44)',
    cannotChangeUserNameMessage: 'The field cannot be changed because this is your username when you log in',
    missingRegNum: 'Registration number is missing for this vehicle',
    isRegnumber: 'This is not a valid license plate number',


  },
  ports: {
    LA: 'Langesund(Oslo)',
    B: 'Bergen',
    HH: 'Hirtshals',
    S: 'Stavanger',
    SS: 'Strømstad',
    K: 'Kristiansand',
    SA: 'Sandefjord',
    ST: 'Strömstad',
    ALB: 'Aalborg'
  },
  stepperInputWrapper: {
    selectValidValue: 'Must be between',
    validValuesFrom: 'Quantity from {{from}} to {{to}}',
    validValuesTo: ' to {{to}}',
  },
  payment: {
    partlyPaid: 'Partly paid',
    partlyPaid_description: '',
    fullyPaid: 'Payment completed',
    fullyPaid_description: '',
    declined: 'Payment declined',
  },
  icons: {
    seaView: 'Sea view',
    shower: 'Shower',
    sofaBed: 'Sofa bed',
    roomForCrib: 'Room for crib',
    doubleBed: 'Double bed',
  },

  payWithFjordClubPoints: {
    headline: 'Pay with Fjord Club points',
    ingress: 'You have {{points}} points. You can use these points to pay for your trip.',
    readMore: 'Read more about this trip',
    bookNow: 'Book now',
    navBar: 'Fjord Club Journeys',
    from: 'From',
    EuTaxLabel: 'per person + EU-tax'
  },
  modificationDeadline: {
    seeWhatCloses: 'See what is expiring',
    timerText: 'The modification deadline for this trip expires in ',
    modalHeadline: 'Modification options expiring',
    deadlineIsPastModal: 'The modification deadline for this trip has passed.',
  }

}
