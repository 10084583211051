import React, { JSX, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Banner } from '@fjordline/styles-v3'
import styled from 'styled-components'

import { CDN_IMG_BECOME_MEMBER } from '../../config'
import { routerRootPaths } from '../../routes/appRoutes/routerPaths'
import { useCheckIfActiveMemberQuery } from '../../graphql'
import { useMyPage } from '../../providers/myPageStateProvider/context'
import { FjordClubMemberStatus } from '../../types/MyPage'
import { useKeycloak } from '../../providers/KeycloakProvider'

const PadTop = styled.div`
  padding-top: 1rem;
`
export default function BecomeMemberBanner(): JSX.Element | null {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { isAuthenticated: kc_isAuth, isLoading: kc_isLoading, token: kc_token } = useKeycloak()
  const isLoading = kc_isLoading ? true : false

  const hasTokenKcToken = kc_token && kc_token.length > 0
  const isAuthenticated = kc_isAuth

  const hasToken = hasTokenKcToken ? true : false
  const { data: isActiveCustomer, loading } = useCheckIfActiveMemberQuery({
    skip: isLoading || !hasToken || !isAuthenticated,
  })
  const { fjordClubMemberStatus } = useMyPage()

  const showBecomeMemberBanner = useMemo(
    () =>
      fjordClubMemberStatus === FjordClubMemberStatus.userHasPartialProfileIsNotMember ||
      fjordClubMemberStatus === FjordClubMemberStatus.userHasNoProfile,
    [fjordClubMemberStatus],
  )

  return isActiveCustomer?.isActiveFjordClubMember === false && !loading && showBecomeMemberBanner ? (
    <PadTop>
      <Banner
        data-cy="become-member-banner"
        target={() => navigate(routerRootPaths.FJORD_CLUB)}
        image={{
          src: CDN_IMG_BECOME_MEMBER,
          alt: `${t('component.fjordClub.join')}`,
        }}
        message={t('component.fjordClub.joinBannerMessage')}
        label={`${t('component.fjordClub.join')}`}
        enableClickEverywhere
      />
    </PadTop>
  ) : null
}
