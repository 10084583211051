import React, { JSX, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, NavLink, useLocation } from 'react-router-dom'
import {
  Boat as MyBookingsIcon,
  SidebarNavIcon,
  SidebarNavItem,
  TopMenu,
  UserStar as FjordClubIcon,
  MenuDialog,
  Coupon,
  // Feedback,
} from '@fjordline/styles-v3'
import { SupportedLocale } from '@fjordline/styles-v3/dist/components/Navigation/TopMenu/LanguageSelector'
import { SidebarNavText } from '@fjordline/styles-v3/dist/components/Navigation/TopMenu/Navigation/style'
import { TFunction } from 'i18next'

import { SHOW_PAY_WITH_CLUB_POINTS_PAGE, USE_BLACK_FRIDAY_THEME } from '../config'
import { useMyPage, useMyPageOperations } from '../providers/myPageStateProvider/context'
import { FjordClubMemberStatus } from '../types/MyPage'
import { Status } from '../types/myPage/enums'

import { routerRootPaths } from '../routes/appRoutes/routerPaths'
import { BookingListNavLink } from '../routes/navLinkFunctions'
import { navLinks } from '../routes/navlinks'
import { extractBookingNumber } from '../providers/WebsocketProvider'
import Login from '../auth/Login'
import { useKeycloak, useKeycloakOperations } from '../providers/KeycloakProvider'
import AddExtrasModalProvider from './navbar/AddExtrasModalProvider'
import MinSideFooter from './navbar/Footer'
import { FooterContainer } from './navbar/footer/footer.styles'
import { TopMenuContainer, StyledSidebarNavDiv } from './navbar/navbar.styles'
// import UpdatingSystemsBanner from '../components/UpdatingSystemsBanner'

// TODO: move this to useMyPage() in the long term, adds app-wide support for theming
const getUseBlackFridayTheme = (fjordClubMemberStatus: FjordClubMemberStatus) => {
  if (!USE_BLACK_FRIDAY_THEME) return false
  if (typeof USE_BLACK_FRIDAY_THEME === 'boolean') return USE_BLACK_FRIDAY_THEME

  // split up for readability
  if (USE_BLACK_FRIDAY_THEME === 'fjordClubOnly') {
    if (fjordClubMemberStatus === FjordClubMemberStatus.userIsFjordClubMember) {
      return true
    }
  }

  return false
}

type ContextChildren = {
  children: string | JSX.Element
}
const Navbar: React.FunctionComponent<React.PropsWithChildren<ContextChildren>> = ({ children }) => {
  const { isAuthenticated: kc_isAuthenticated } = useKeycloak()
  const { logout: kc_logout } = useKeycloakOperations()

  const isAuthenticated = kc_isAuthenticated

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [navigationLinkItems, setNavigationLinkItems] = useState<any>() // TODO: see navigationItems prop type in TopMenu below
  const [locales, setLocales] = useState({})

  const { t, i18n } = useTranslation()
  const bookingListNavLink = useMemo(() => BookingListNavLink({ bookingListView: Status.FUTURE }), [])
  const { changesNotSaved, setShowChangesNotSavedModal, setFjordClubMemberStatusNoProfile } = useMyPageOperations()
  const { isLoading: kc_isLoading } = useKeycloak()

  const isLoading = kc_isLoading

  //Set english language if the current language is not supported
  useEffect(() => {
    if (locales && Object.keys(locales).length > 0 && !Object.keys(locales).includes(i18n.language)) {
      i18n.changeLanguage('en')
    }
  }, [i18n, locales])

  const location = useLocation()
  const { fjordClubMemberStatus } = useMyPage()

  const useBlackFridayTheme = getUseBlackFridayTheme(fjordClubMemberStatus)

  useEffect(() => {
    if (!isLoading && !isAuthenticated && fjordClubMemberStatus === FjordClubMemberStatus.userIsNotAuthenticated) {
      setFjordClubMemberStatusNoProfile()
    }
  }, [fjordClubMemberStatus, isAuthenticated, isLoading, setFjordClubMemberStatusNoProfile])

  const [loginModalOpen, setLoginModalOpen] = useState(false)

  useEffect(() => {
    setNavigationLinkItems(
      getNavigationLinkItems(
        t,
        isAuthenticated,

        bookingListNavLink,
        fjordClubMemberStatus,
        changesNotSaved,
        setShowChangesNotSavedModal as React.Dispatch<React.SetStateAction<boolean>>,
        loginModalOpen,
        setLoginModalOpen,
        useBlackFridayTheme,
      ),
    )
  }, [
    bookingListNavLink,
    changesNotSaved,
    fjordClubMemberStatus,
    isAuthenticated,
    loginModalOpen,
    setShowChangesNotSavedModal,
    t,
    useBlackFridayTheme,
  ])

  useEffect(() => {
    setLocales(getAvailableLocales(t))
  }, [t, i18n.language])

  // Remark:  children in TopMenuContainer is wrapped in AddExtrasModalProvider
  //          thus showAddExtrasModal is not available in the sidebar, only inside the container.
  //          To enable showAddExtrasModal, TopMenu must be wrapped as well.

  const showOnlyCurrentLocale = Object.entries(locales).reduce((acc, [key, value]) => {
    if (key === i18n.language) {
      acc[key] = value
    }
    return acc
  }, {})

  return (
    <>
      <TopMenu
        blackFridayTheme={useBlackFridayTheme}
        currentLocale={i18n.language as SupportedLocale}
        locales={extractBookingNumber(location) ? showOnlyCurrentLocale : locales}
        slidesFromTop
        onLogout={
          kc_isAuthenticated
            ? () => {
                if (kc_isAuthenticated) {
                  kc_logout()
                }
              }
            : undefined
        }
        onLocaleChange={(newLocale) => i18n.changeLanguage(newLocale)}
        homeHref={`https://fjordline.com/${i18n.language || ''}`}
        navigationItems={navigationLinkItems}
        data-cy="top-menu"
      >
        <TopMenuContainer>
          <AddExtrasModalProvider>{children}</AddExtrasModalProvider>
        </TopMenuContainer>
      </TopMenu>
      <FooterContainer>
        <MinSideFooter />
      </FooterContainer>
    </>
  )
}

export default Navbar

function getNavigationLinkItems(
  t,
  isAuthenticated: boolean,

  bookingListNavLink: string,
  fjordClubMemberStatus?: FjordClubMemberStatus | undefined,
  changesNotSaved?: boolean,
  setShowChangesNotSavedModal?: React.Dispatch<React.SetStateAction<boolean>>,
  loginModalOpen?: boolean,
  setLoginModalOpen?: React.Dispatch<React.SetStateAction<boolean>>,
  useBlackFridayTheme?: boolean,
): JSX.Element {
  return isAuthenticated ? (
    <>
      <SidebarNavItem data-cy="li-fc" onClick={collapseMenu} blackFridayTheme={useBlackFridayTheme}>
        <Link
          onClick={() => {
            if (changesNotSaved && setShowChangesNotSavedModal) {
              setShowChangesNotSavedModal(true)
            }
          }}
          to={
            changesNotSaved
              ? navLinks.EDIT_MEMBERSHIP
              : fjordClubMemberStatus === FjordClubMemberStatus.userHasNoProfile
                ? navLinks.SIGN_UP
                : navLinks.FJORD_CLUB
          }
          key={routerRootPaths.FJORD_CLUB}
        >
          <StyledSidebarNavDiv>
            <SidebarNavIcon blackFridayTheme={useBlackFridayTheme}>
              <FjordClubIcon />
            </SidebarNavIcon>
            <SidebarNavText blackFridayTheme={useBlackFridayTheme}>{t('navbar.fjordClub')}</SidebarNavText>
          </StyledSidebarNavDiv>
        </Link>
      </SidebarNavItem>
      <SidebarNavItem data-cy="li-my-trips" onClick={collapseMenu} blackFridayTheme={useBlackFridayTheme}>
        <NavLink
          onClick={() => {
            if (changesNotSaved && setShowChangesNotSavedModal) {
              setShowChangesNotSavedModal(true)
            }
          }}
          to={changesNotSaved ? navLinks.EDIT_MEMBERSHIP : bookingListNavLink}
          key={routerRootPaths.BOOKING_LIST}
        >
          <StyledSidebarNavDiv>
            <SidebarNavIcon blackFridayTheme={useBlackFridayTheme}>
              <MyBookingsIcon />
            </SidebarNavIcon>
            <SidebarNavText blackFridayTheme={useBlackFridayTheme}>{t('navbar.bookings')}</SidebarNavText>
          </StyledSidebarNavDiv>
        </NavLink>
      </SidebarNavItem>
      {SHOW_PAY_WITH_CLUB_POINTS_PAGE && (
        <SidebarNavItem data-cy="li-pay-with-club-points" onClick={collapseMenu} blackFridayTheme={useBlackFridayTheme}>
          <NavLink
            onClick={() => {
              if (changesNotSaved && setShowChangesNotSavedModal) {
                setShowChangesNotSavedModal(true)
              }
            }}
            to={navLinks.PayWithPoints}
            key={routerRootPaths.PAY_WITH_CLUB_POINTS}
          >
            <StyledSidebarNavDiv>
              <SidebarNavIcon blackFridayTheme={useBlackFridayTheme}>
                <Coupon />
              </SidebarNavIcon>
              <SidebarNavText blackFridayTheme={useBlackFridayTheme}>
                {t('payWithFjordClubPoints.navBar')}
              </SidebarNavText>
            </StyledSidebarNavDiv>
          </NavLink>
        </SidebarNavItem>
      )}
    </>
  ) : (
    <div>
      {loginModalOpen && setLoginModalOpen ? (
        <MenuDialog onRequestClose={() => setLoginModalOpen(false)}>
          <Login />
        </MenuDialog>
      ) : null}
    </div>
  )
}

function getAvailableLocales(t: TFunction<string>) {
  return {
    da: t('language.da', 'danish'),
    de: t('language.de', 'german'),
    en: t('language.en', 'english'),
    nb: t('language.nb', 'norwegian'),
  }
}

function collapseMenu(): void {
  document.dispatchEvent(
    new KeyboardEvent('keydown', {
      key: 'Escape',
      code: 'Escape',
    }),
  )
}
