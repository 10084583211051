import { H1, H2, H3, OptionHeader, Paragraph } from '@fjordline/styles-v3'
import React, { useState } from 'react'
import useFetchPayableWithClubPointsFromSanity from '../sanity/cruise/useFetchPayableWithClubPointsJourneys'
import { useTranslation } from 'react-i18next'
import { imageUrlFor } from '../sanity/imageUrlBuilder'
import { ENVIRONMENT } from '../config'
import { useGetCustomerQuery } from '../graphql'
import { JourneyStyledCard, JourneyStyledContainer } from './PayWithClubPoints.styled'

export function PayWithClubPoints() {
  const { t } = useTranslation()
  const { data } = useGetCustomerQuery()
  const clubPoints = data?.customer.loyaltyPoints?.redeemablePoints
  const [chooseOption, setChooseOption] = useState<number>(1)
  const { payableWithClubPoints } = useFetchPayableWithClubPointsFromSanity({
    showFCPoints: chooseOption === 1 ? true : false,
  })

  return (
    <>
      <H1 style={{ marginLeft: '1rem', marginTop: '2rem' }}>Fjord Club {t('bookingListStatus.journeys')}</H1>
      {chooseOption === 1 ? (
        <H3 style={{ marginLeft: '1rem' }}> {t('payWithFjordClubPoints.ingress', { points: clubPoints })} </H3>
      ) : (
        <H3 style={{ marginLeft: '1rem', marginBottom: '2.5rem' }}></H3>
      )}
      <div style={{ width: '95%', marginLeft: '1rem' }}>
        <OptionHeader
          options={[
            {
              id: 1,
              isSelected: chooseOption === 1 ? true : false,
              title: t('payWithFjordClubPoints.headline'),
              onClick: () => {
                setChooseOption(1)
              },
            },
            {
              id: 2,
              isSelected: chooseOption === 2 ? true : false,
              title: 'Fjord Club ' + ' ' + t('bookingListStatus.journeys'),
              onClick: () => {
                setChooseOption(2)
              },
            },
          ]}
        />
      </div>
      <JourneyStyledContainer>
        {payableWithClubPoints.map((journey, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <JourneyCard key={index} journey={journey} />
        ))}
      </JourneyStyledContainer>
    </>
  )
}

type JourneyCardProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  journey: any
}
const JourneyCard = ({ journey }: JourneyCardProps) => {
  const { i18n, t } = useTranslation()
  const headerImage =
    imageUrlFor(journey?.metaImage?.asset?._ref ?? journey?.images?.[0]?.asset?._ref)?.url() ??
    'https://cdn.sanity.io/images/90kslno9/homeport/6ed26d17ee7d969dbd8cedbb1e35cad5ccf61bb6-1920x1230.webp'

  const mainPage = ENVIRONMENT === 'PROD' ? 'https://www.fjordline.com' : 'https://www-stage.fjordline.com'

  const readMoreUrl = `${mainPage}/${i18n.language}/tilbud-batreiser/${journey.shortId}/${journey?.urlSlug?.[i18n.language]?.current ? journey?.urlSlug?.[i18n.language]?.current : ''}`

  const priceNordic = i18n.language === 'nb' || i18n.language === 'da' ? ',-' : ''
  const priceEU = i18n.language === 'en' || i18n.language === 'de' ? '€' : ''

  return (
    <JourneyStyledCard>
      <div className="header-image">
        {journey?.payableWithPoints?.toString() === '100031' && (
          <div className="pay_with_points_badge">
            <span>{t('payWithFjordClubPoints.navBar')}</span>
          </div>
        )}
        <img
          onClick={() => {
            window.open(readMoreUrl, '_blank')
          }}
          src={headerImage}
          alt="journey"
        />
      </div>
      <div className="body">
        <H2 style={{ margin: '0' }}>{journey.title?.[i18n.language] ?? journey.name}</H2>
        <Paragraph>{journey.summary?.[i18n.language]}</Paragraph>
      </div>
      <div className="footer">
        <span className="foooter_price">
          <small className="footer_price-from">{t('payWithFjordClubPoints.from')}</small>
          <span className="footer_price-title">
            {priceEU}
            {journey?.priceAmount?.[i18n.language]} {priceNordic}
          </span>
          <span className="footer_price_deparature">{journey.priceDescription?.[i18n.language]}</span>
        </span>
        <a href={readMoreUrl} className="footer_button">
          {t('component.extras.common.readMore')}
        </a>
      </div>
    </JourneyStyledCard>
  )
}
