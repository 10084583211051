import React, { Suspense, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Divider, FjordClubMemberCard, FormElementWrapper, H1, Paragraph, Button } from '@fjordline/styles-v3'

import CustomBackButton from '../components/CustomBackbutton'
import { navLinks } from '../routes/navlinks'
import { useMyPage, useMyPageOperations } from '../providers/myPageStateProvider/context'
import { FjordClubMemberStatus } from '../types/MyPage'

import FjordClubCustomerMembership from './fjordClub/FjordClubCustomerMembership'
import FjordClubTravelFriendList from './fjordClub/FjordClubTravelFriendList'
import LottieLoaderContainer from '../components/LottieLoader'
import { useKeycloak } from '../providers/KeycloakProvider'
import styled from 'styled-components'

const FjordClub: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { fjordClubMemberStatus } = useMyPage()
  const { customer } = useMyPage()
  const { isAuthenticated, isLoading } = useKeycloak()
  const { setFjordClubMemberStatusNoProfile } = useMyPageOperations()

  useEffect(() => {
    if (!isLoading && !isAuthenticated && fjordClubMemberStatus === FjordClubMemberStatus.userIsNotAuthenticated) {
      setFjordClubMemberStatusNoProfile()
    }
  }, [fjordClubMemberStatus, isAuthenticated, isLoading, setFjordClubMemberStatusNoProfile])

  useMemo(() => window.scrollTo(0, 0), [])
  useEffect(
    function redirectUserToSignupIfHasNoOrPartialProfile() {
      if (isLoading || !isAuthenticated) return

      if (
        fjordClubMemberStatus === FjordClubMemberStatus.userHasNoProfile ||
        fjordClubMemberStatus === FjordClubMemberStatus.userHasPartialProfileIsNotMember
      ) {
        navigate(navLinks.SIGN_UP_ADD)
      }
    },
    [fjordClubMemberStatus, isAuthenticated, isLoading, navigate],
  )

  return (
    <div style={{ maxWidth: '50rem' }}>
      <FormElementWrapper>
        <CustomBackButton defaultBackLinkTo={navLinks.BOOKING_LIST} />
      </FormElementWrapper>
      <H1>{t('component.fjordClub.header')}</H1>
      {fjordClubMemberStatus === FjordClubMemberStatus.userIsFjordClubMember ? (
        <>
          <FormElementWrapper>
            <FjordClubSVGWrapper>
              <FjordClubMemberCard
                name={`${customer?.firstName} ${customer?.lastName}`}
                title={customer?.clubMemberships?.[0]?.clubLevelCode || ''}
                memberNumber={customer?.clubMemberships?.[0]?.membershipNumber || ''}
                qrCodeValue={customer?.clubMemberships?.[0]?.membershipNumber || ''}
              />
            </FjordClubSVGWrapper>
          </FormElementWrapper>
          <Divider />
          <Suspense fallback={<LottieLoaderContainer />}>
            <FjordClubCustomerMembership />
          </Suspense>
          <Suspense fallback={<LottieLoaderContainer />}>
            <FjordClubTravelFriendList />
          </Suspense>{' '}
        </>
      ) : (
        <>
          <Paragraph>{t('component.fjordClub.notMemberYet')}</Paragraph>
          <Button size='medium' onClick={() => navigate(navLinks.SIGN_UP_ADD)}>{t('component.fjordClub.signUpBtn')}</Button>
        </>
      )}
    </div>
  )
}

export default FjordClub

const FjordClubSVGWrapper = styled.div`
  svg {
    width: 100%;
    height: auto;
    max-width: 500px;
  }
`
