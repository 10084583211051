import React from 'react'
import { ArrowLeft, Button, H1, Paragraph, Divider } from '@fjordline/styles-v3'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ReportIssueButton } from '../../components/ReportIssue'
import { styled } from 'styled-components'

type ErrorPageProps = {
  type: 'bookingList' | 'bookingDetails'
  bookingCode?: string
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 5rem;
  text-align: center;
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1rem;
  padding: 2rem;
`

export const ErrorPage: React.FC<ErrorPageProps> = ({ type, bookingCode }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <Container>
      <H1>{t('error.somethingWrong')}</H1>

      {type === 'bookingList' && (
        <Paragraph>{t('error.booking.bookingListFailed')}</Paragraph>
      )}

      {type === 'bookingDetails' && (
        <Paragraph>{t('error.booking.detailsNotFound')}{ bookingCode ? ( <> {t('error.booking.detailsNotFoundBookingCode')} <strong>{bookingCode}</strong>.</> ) : '.'}</Paragraph>
      )}

      <Paragraph>{t('error.booking.problemPersists')}</Paragraph>

      <Divider />

      <ButtonWrapper>
        <Button
          onClick={() => navigate('/bookingList')}
          theme="primary"
          size="large"
          leadingIcon={ArrowLeft}
        >
          {t('label.button.backToBookings')}
        </Button>
        <ReportIssueButton />
      </ButtonWrapper>
    </Container>
  )
}
