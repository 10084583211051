/* eslint-disable @typescript-eslint/naming-convention */
import CarRes from '../CarRes/nb'

type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>
}

export const translation = {
  language: {
    da: 'Dansk',
    de: 'Deutsch',
    en: 'English',
    nb: 'Norsk',
  },
  country: {
    da: 'Danmark',
    de: 'Tyskland',
    en: 'England',
    nb: 'Norge',
    nl: 'Nederland',
  },
  number: {
    1: 'En',
    2: 'To',
    3: 'Tre',
    4: 'Fire',
    5: 'Fem',
    6: 'Seks',
    7: 'Sju',
    8: 'Åtte',
    9: 'Ni',
    10: 'Ti',
    11: 'Elleve',
    12: 'Tolv',
    13: 'Tretten',
    14: 'Fjorten',
    15: 'Femten',
  },
  cartException: {
    ALittleBitKnown: 'ALittleBitKnown',
    Known: 'Known',
    Unknown: 'Unknown',
  },
  bookingListStatus: {
    journeys: 'reiser',
    ACTIVE_AND_FUTURE: 'Aktive og fremtidige $t(bookingListStatus.journeys)',
    CANCELLED: 'Kansellerte reiser $t(bookingListStatus.journeys)',
    PASSED: 'Passerte $t(bookingListStatus.journeys)',
    DEFAULT: '$t(bookingListStatus.ACTIVE_AND_FUTURE)',
  },
  //COMPONENT
  component: {
    general: {
      loading: 'Laster',
      departure: 'Utreise',
      return: 'Retur',
      continue: 'Fortsett',
      updatingBooking: 'Oppdaterer bookingdetaljer...',
      bookingIsUpdated: 'Booking {{bookingCode}} er oppdatert!',
    },
    welcome: {
      toMyPage: 'Velkommen til Min Side',
      oldMyPage: 'Opplever du problemer med ny versjon av Min Side?',
      goToOldMyPage: 'Gå tilbake til gammel Min Side',
      generalInfo:
        'Her finner du oversikten over dine reiser og/eller Fjord Club-medlemskapet ditt. Om du ennå ikke er Fjord Club-medlem, kan du melde deg inn her.',
    },
    auth: {
      verifyAndProceed: 'Verifiser og gå videre',
      header: 'Min Side',
      login: 'Logg inn',
      logout: 'Logg ut',
      noCode: 'Ikke mottatt kode?',
      oneTimeCode: 'Engangskode',
      email: 'E-post',
      phone: 'Mobilnummer',
      resendCode: 'Send ny engangskode',
      codeInstructions: 'Dette vinduet må ikke lukkes. Åpne en ny fane for å hente engangskoden',
      loginError: 'Det har oppstått en feil, vennligst prøv igjen.',
      facebookEmailRequired:
        'Vi må ha tilgang til din e-postadresse for at Min Side skal fungere. Logg inn på nytt og gi tilgang til e-postadressen din eller velg et annet innloggingsalternativ.',
      auth0Error: {
        access_denied: 'Feil engangskode. Sjekk at den er skrevet inn riktig og prøv igjen.',
      },
      multipleProfiled: {
        header: 'Ditt brukernavn',
        ingress1: 'Det finnes mer enn én profil tilknyttet ditt brukernavn ({{userName}})',
        ingress2:
          'Vi har registrert en sak på dette og kontakter deg så snart saken er løst eller dersom vi trenger mer informasjon fra deg',
        ingress3: 'Kontakt kundesenteret slik at de kan hjelpe deg',
        phone: 'Telefon',
        email: 'E-post',
        buttonText: 'Lukk',

      },
      renew: {
        headline: 'Du må fornye din bruker',
        paragraph1: 'Vi har oppgradert våre innloggings systemer, og du må derfor fornye din bruker. Du kan bruke samme epost og passord som du har brukt tidligere. ',
        paragraph2: 'Alle opprinnlige opplysninger og bookinger vil fortsatt være tilgjengelige.',
        renewBtn: 'Forny bruker',
      },
      emailNeedsVerification:
        'Før du kan logge inn for første gang trenger vi å verifisere din e-postadresse. Skriv inn engangskoden du har fått tilsendt din e-post.',
      emailVerified: 'Din e-postadresse er verifisert og du kan nå logge inn.',
      signUp: 'Registrer deg',
      codeResent: 'Ny engangskode er sendt til din e-postadresse',
    },

    cancelBooking: {
      cost: 'Kansellering av denne reisen koster',
      disclaimer: 'Denne handlingen kan ikke reverseres.',
      info: 'Avbestillingsgebyr varierer ut fra billettype og avbestillingstidspunkt.',
     description: 'Eventuell refusjon beregnes og utbetales i henhold til vilkårene som ble oppgitt ved bestilling. ',
     readMore: 'Les mer om våre avbestillingsvilkår her.',
      prompt: 'Er du sikker på at du vil avbestille din reise?',
      title: 'Kanseller bestilling',
      dontCancel: 'Jeg ønsker ikke å kansellere. Gå tilbake',
      notify: {
        loading: 'Kansellering pågår...',
        success: 'Kansellering utført!',
        failure: 'Kansellering mislykkes!',
      },
    },
    requestBookingModification: {
      button: 'Endring av reise',
      header: 'Endring av reise',
      content:
        'Velg å bli kontaktet for å om-booke reisen, eller få tilsendt en verdikupong som kan brukes på fremtidige reiser.',
      reArrange: 'Om-book',
      valueCheck: 'Verdikupong',
      success: 'Melding sendt, du hører fra oss innen kort tid.',
      error: 'Melding ble ikke sendt på grunn av en feil. Prøv igjen senere eller ta kontakt med kundeservice.',
    },
    //component.extras
    extras: {
      button: 'Vis tilleggsprodukter',
      noExtras: 'Du har ennå ikke bestilt noen tilleggsprodukter',
      currencyInfo: 'Valutaen som vises er den du bestilte reisen med.',
      //component.extras.cabin
      cabin: {
        title: 'Lugarer',
        title_seat: 'Sete',
        loungeError: 'Lounge er ikke tilgjengelig for valgt antall passasjerer.',
        booked:
          'Reservert: $t(component.extras.cabin.qty, { \'count\': {{count}} }) med $t(component.extras.cabin.specQty, { \'count\': {{specQty}} })',
        booked_plural:
          'Reservert: $t(component.extras.cabin.qty, { \'count\': {{count}} }) med $t(component.extras.cabin.specQty, { \'count\': {{specQty}} }) hver',
        booked_noseats: '$t(component.extras.cabin.specQty, { \'count\': {{specQty}} }) uten spesifisert sete',
        qty: '{{count}} lugar',
        qty_plural: '{{count}} lugarer',
        specQty: '{{count}} passasjer',
        specQty_plural: '{{count}} passasjerer',
        spec: CarRes.cabinSpec,
        changeCabin: 'Endre lugar',
        chooseCabins: 'Velg lugarer',
        searchingForCabins: 'Søker etter ledige lugarer',
        passengersToPlace_plural: 'Du må plassere {{count}} passasjerer før du kan fortsette',
        passengersToPlace_singular: 'Du må plassere {{count}} passasjer før du kan fortsette',
        addCabin: 'Endre/legg til lugar',
        addSeat: 'Legg til sete',
        selectedCabins: 'Nåværende bestilling:',
        newCabins: 'Ny bestilling:',
        removeCabin: 'Fjern',
        passengersToPlaceModal: 'Du må plassere {{count}}',
        totalPrice: 'Total pris',
        lowerPriceWarning: 'Lugarene du har valgt koster mindre enn dine nåværende lugarer.',
        allPassengersPlaced: 'Alle passasjerer er plasserte!',
        window: 'Vindu',
        persons: 'Personer',
        price: 'Pris',
        crib: 'Plass til barneseng: ',
        goToBasketToast: 'Alle passasjerer er plasserte. Gå til handlekurv',
        cabinOutbound_basket: 'Lugar utreise',
        cabinInbound_basket: 'Lugar returreise',
        changeCabin_basket: 'Klikk her for å endre lugar.',
        addMissingCabin_basket: 'Legg til manglende lugar',
        allPassengersMustBePlaced_basket: 'Alle passasjerer må plasseres før du kan fullføre betalingen.',
        noAvailability: 'Ingen tilgjengelige lugarer på denne reisen.',
        oldCabins_basket: 'Lugarer som vil fjernes: ',
        upgradeCabin: 'Oppgrader lugar',
        quantityInCabin: 'Antall personer',
        seeAll: 'Se vårt utvalg',
        priceUpgrade: 'Pris oppgradering',
        feedback: {
          toast: {
           minReq: 'Du må velge minst 1 person for å legge til denne lugaren.',
            minOrd: 'Denne lugaren har en minimumsbestilling på {{minOrd}} personer. Du har {{passengersYetToPlace}} personer igjen å plassere.',
          },
          deleteBeforeAdd: 'Beklager, alle passasjerer er allerede tildelt plassering. Hvis du ønsker å endre plasseringen, må du først fjerne noen passasjerer fra det valgte lugar- eller setevalget.',
          missingPassengersPlaced: 'Du har plassert {{passengersPlaced}} av {{passengersToPlace}} passasjerer. Alle passasjerer må plasseres for å kunne gå til handlekurv.',
          newPriceIsLessThanExistingPrice: 'Valgte lugarer koster mindre enn dine eksisterende. Vennligst velg andre eller flere lugarer slik at oppgraderte lugarer overstiger prisen til eksisterende bestilte.',
          allPassengersPlaced: 'Alle passasjerer er plasserte!',

          basket: {
            allPassengersPlacedError: 'Alle passasjerer på {{errorType}} er ikke plassert. Vennligst legg til manglende lugar/sete for å kunne gå til betaling, eller fjern disse fra handlekurven.',
            missingCabins: 'Manglende lugar/sete',
            goToCabins: 'Gå til lugarer',
            removeCabins: 'Fjern lugarer',
            existingRemoved: 'Fjernes',
          }

        }
      },
      //component.extras.common
      common: {
        arrival: 'Returreise',
        count: 'Antall',
        departure: 'Utreise',
        readMore: 'Les mer',
        deleteHeader: {
          remove: 'Fjerne',
          itemGeneral: '$t(component.extras.common.deleteHeader.remove) produkt',
          cabins: '$t(component.extras.common.deleteHeader.remove) lugar',
          extrasAshore: '$t(component.extras.common.deleteHeader.remove) ekstra på land',
          extrasOnboardInbound: '$t(component.extras.common.deleteHeader.remove) ekstra ombord',
          extrasOnboardOutbound: '$t(component.extras.common.deleteHeader.remove) ekstra ombord',
          meals: '$t(component.extras.common.deleteHeader.remove) måltid',
          meetingRooms: '$t(component.extras.common.deleteHeader.remove) møterom',
        },
        deleteItem: {
          itemGeneral: 'dette produktet',
          cabins: 'denne lugaren',
          extrasAshore: 'dette produktet',
          extrasOnboardInbound: '$t(component.extras.common.deleteItem.extrasAshore)',
          extrasOnboardOutbound: '$t(component.extras.common.deleteItem.extrasAshore)',
          meals: 'dette måltidet',
          meetingRooms: 'dette møterommet',
          deleteItemQuestion:
            'Vil du fjerne $t(component.extras.common.deleteItem.{{extraCategory}}) fra handlekurven?',
        },
      },
      taxesAndFees: {
        headline: 'Skatter og avgifter',
        envTax: 'EU-miljøavgift',
      },
      //component.extras.meal
      meal: {
        add: 'Legg til måltider',
        arrival: 'Returreise',
        count: 'Antall',
        departure: 'Utreise',
        existingOrder: 'Bestilt og betalt',
        qty: 'Antall reservasjoner: {{count}} Passasjer',
        qty_plural: 'Antall reservasjoner: {{count}} Passasjerer',
        selectDay: 'Velg dag',
        chooseTime: 'Velg bordsetting',
        spec: CarRes.mealSpec,
        spec_plural: CarRes.mealSpec,
        time: 'Reservasjonstidspunkt: {{time}}',
        title: 'Måltider',
        totalPrice: 'Total pris: {{amount}}',
        yourMeals: 'Dine måltider',
        nomeals: 'Ingen reservasjon',
        meal: 'måltid',
        meals: 'måltider',
        fromPrice: 'Fra',
        buttonLabelCount: 'Velg antall',
        noDetailsAvailable: 'Ingen detaljer tilgjengelig for øyeblikket',
        labelTime: 'Velg tidspunkt',
        searchingForMeals: 'Søker etter tilgjengelige måltider',
        deleteToastMessage: '{{mealName}} slettet fra handlekurven',
        AddOneToastMessage: '{{specName}} lagt til',
        RemoveOneToastMessage: '{{specName}} fjernet',
        loadingMeals: 'Henter tilgjengelige måltider',
        inTotal: 'Totalt',
        noMealsAvailable: 'Ingen tilgjengelige måltid',
        adult: 'voksen',
        adults: 'voksne',
        baby: 'baby',
        child: 'barn',
        infant: 'spedbarn',
        sixcour: 'seks-retters',
        fivecour: 'fem-retters',
        fourcour: 'fire-retters',
        threecour: 'tre-retters',
        discount: 'Bestill nå og få 20% avslag på alle måltider',
      },
      //component.extras.basket
      basket: {
        conditions: {
          ingress: 'Jeg har lest og forstått',
          terms: 'betingelsene',
          force: 'Du må akseptere betingelsene',
        },
        added: 'Lagt til',
        sumMeal: 'Sum måltid',
        totalToPay: 'Totalt å betale',
        completeOrder: 'Fullfør kjøp',
        cancellingOrder: 'Handlekurven tømmes...',
        availableSoon: ' kommer snart...',
        receipt: 'Kvittering',
        thankYou: 'Takk for din bestilling',
        payed: 'Betalt',
        remainsToPay: 'Gjenstår å betale',
        timeoutError: 'Noe gikk galt. Prøv en gang til',
        processingOrder: 'Din ordre er under behandling. Vennligst vent...',
        deleteOrder: 'Tøm handlekurven og start på nytt',
        retry: 'Prøv igjen',
        orderCancelled: 'Ordre kansellert',
        cancelOrder: 'Tøm handlekurven',
        paymentFailed: 'Betalingen kunne ikke gjennomføres',
        basketInfoErrorMessage: 'Beklager, noe gikk galt i betalingen.',
        paymentErrorMessage:
          'Det er sannsynlig at betalingen for disse varene allerede er gjennomført. Gå tilbake til Mine reiser og sjekk status på reisen',
        goToPayment: 'Gå til betaling',
        orderIsReady: 'Ordren din er klar',
        showOrderAndPay: 'Vis handlekurven og betal',
        empty: 'Handlekurven er tom...',
        alreadyReservedInCart_description: 'Du må betale eller fjerne reserverte varer før du kan legge til flere varer',
        emptyCart: 'Tøm handlekurv',
        alreadyReservedInCart: 'Du har allerede reserverte varer i handlekurven',
        seeCart: 'Se handlekurv',

      },
      misc: {
        title: 'Diverse',
        ashoreTitle: 'Ekstra i land',
        onboardTitle: 'Ekstra ombord',
        other: 'Annet',
        qty: '{{count}} stk',
        onShoreTickets: '{{count}} billett(er)',
        tickets: 'Se billetter',
        loadingExtraOnboard: 'Henter tilgjengelige tilleggsprodukter ombord',
        loadingExtraAshore: 'Henter tilgjengelige tilleggsprodukter i land',
        noExtrasAvailable: 'Det er ingen tilgjengelige tilleggsprodukter for reisen din',
        noExtrasAvailableOnboard: 'Det er ingen tilgjengelige ombordprodukter for reisen din',
        noExtrasAvailableAshore: 'Det er ingen tilgjengelige produkter i land for reisen din',
      },
      ashore: {
        title: 'Overnatting i land',
        booked: 'Reservert: $t(component.extras.ashore.qty, { \'count\': {{count}} })',
        qty: '{{count}} ',
        qty_plural: '{{count}} ',
        checkin: 'Innsjekk',
        checkout: 'Utsjekk',
        roomType: 'Rom',
      },
    },
    fjordClub: {
      header: 'Fjord Club',
      updated: 'Fjord Club medlemskapet ditt er oppdatert',
      registered: 'Ditt Fjord Club medlemskap er registrert.',
      unsavedChanges: 'Du har ulagrede endringer. Ønsker du å lagre endringene?',
      terms: {
        link: 'https://www.fjordline.com/nb/p/fjord-club/vilkar-og-betingelser',
        view: 'Se betingelser',
      },
      consent:
        'Jeg samtykker i Fjord Clubs vilkår og betingelser herunder mottak av e-post fra Fjord Line, og at Fjord Line lagrer mine person- og kjøpsopplysninger.',
      description:
        'Fjord Club er vår måte å sette pris på deg som liker å reise med Fjord Line. Bli medlem gratis og du kan opptjene poeng på dine reiser og motta eksklusive tilbud og rabatter.',
      delay:
        'NB: Etter du har opprettet Fjord Club profil kan det ta opp til en dag før profilen hentes automatisk når du logger inn. Profilen kan alltid hentes manuelt ved å fylle ut påkrevde felter og sende inn skjemaet på nytt.',
      join: 'Registrer ditt Fjord Club medlemskap',
      join_description: 'Fjord Club er vår måte å sette pris på deg som liker å reise med Fjord Line. Bli medlem gratis og du kan opptjene poeng på dine reiser og motta eksklusive tilbud og rabatter.',
      minimumAge: 'Man må ha fylt 18 år for å bli medlem av Fjord Club.',
      joinBannerMessage: 'Alltid fordeler med Fjord Club - Bli medlem gratis!',
      fcMemberCreateFailed: 'Vi opplever midlertidige problemer med medlemskapsopprettelsen. Vennligst prøv igjen senere eller kontakt vår kundeservice for om problemet vedvarer.',
      fcMemberUpdateFailed: 'Det ser ut som vi har problemer med å oppdatere medlemskap for øyeblikket. Prøv igjen senere, eller kontakt kundeservice om problemet vedvarer.',
      mustVerifyMobileFeedback: 'Du må verifisere ditt mobilnummer for å lagre.',
      manualVerificationMessage: 'Ditt telefonnummer kan ikke motta verifiseringskode automatisk. Ta kontakt med kundeservice for å verifisere ditt telefonnumer. Kundeservice vil kunne opprette en kode for deg, som du skriver inn i feltet under.',
      contactCustomerService: 'Kontakt kundeservice',
    },
    boardingCards: {
      button: 'Åpne boardingkort',
      header: 'Boardingkort',
      messageNotAvailable:
        'Boardingkort vil bli tilgjengelig {{hoursBeforeJourneyStartToShowBoardingCard}} timer før avreise',
      messageNotAvailableTitle: 'MERK!',
      departure: 'utreise',
      arrival: 'returreise',
      switchTo: 'Bytt til {{journey}}',
      messageBookingPassedOrCancelledIngress: 'Boardingkortet er ikke tilgjengelig',
      messageBookingPassedOrCancelled: 'Denne reisen er enten kansellert eller fullført',
      errorMessageIngress: 'Ukjent booking',
      errorMessage: 'Kan ikke hente boardingkort',
    },
    footer: {
      contactUs: 'Kontakt oss',
      followUs: 'Følg oss',
    },
    editPassengers: {
      updated: 'Passasjerinformasjon er oppdatert!',
    },
    editVehicles: {
      updated: 'Registreringsnummer er oppdatert!',
      error: 'Noe gikk galt. Kunne ikke oppdatere. Prøv igjen senere, eller kontakt kundeservice om problemet vedvarer.'
    },
    reportIssue: {
      button: {
        reportIssue: 'Rapporter et problem',
        submitReport: 'Send rapport',
        cancel: 'Avbryt',
      },
      modalTitle: 'Rapporter et problem',
      modalIngress: "Vi er her for å hjelpe! Gi oss beskjed hvis du opplever noen problemer, så vil vi jobbe med å løse dem så raskt som mulig.",
      issueTypes: {
        missingBookings: 'Manglende bestilling(er)',
        bookingDetails: 'Kan ikke se bestillingsdetaljer',
        otherIssue: 'Annet problem'
      },
      fieldLabels: {
        issueType: 'Hvilken type problem opplever du?',
        bookingCode: 'Bestillingsnummer (8-sifret nummer)',
        description: 'Beskriv problemet'
      },
      feedback: {
        success: 'Takk! Problemet ditt har blitt rapportert.',
        error: 'Noe gikk galt. Hvis problemet vedvarer, kontakt oss på info@fjordline.com',
        missingField: 'Vennligst fyll ut de obligatoriske feltene.',
        invalidBookingCode: 'Vennligst skriv inn et gyldig bestillingsnummer på 8 sifre.'
      }
    }
  },
  //END OF COMPONENT
  metadata: {
    title: {
      booking: 'Reise {{bookingCode}}',
      bookings: 'Mine reiser',
      extras: 'Tilleggsprodukter {{bookingCode}}',
      login: 'Min Side Login',
      fjordClub: 'Fjord Club',
      accommodation: 'Overnatting',
      default: 'Min side - Fjord Line',
    },
    traveller: {
      ADL: 'Voksne 16+',
      CHD: 'Barn 4-15 år',
      INF: 'Spedbarn',
      BABY: 'Baby 0-11 mnd',
    },
  },
  navbar: {
    bookings: '$t(metadata.title.bookings)',
    fjordClub: '$t(metadata.title.fjordClub)',
    login: '$t(component.auth.login)',
    logout: '$t(component.auth.logout)',
    menu: 'Menu',
  },
  addModal: {
    extrasOnboard: 'Ekstra ombord',
    extrasAshore: 'Ekstra i land',
  },
  error: {
    reset: 'Gå tilbake til Min Side',
    somethingWrong: 'Noe gikk galt',
    timeout: {
      title: 'Ingen respons fra serveren',
      message:
        'Det ser ut til at det tar for lang tid å få svar fra serveren. Dette kan skyldes enten dårlig tilkobling eller en feil med serverne våre. Vennligst forsøk å laste inn siden på nytt på et senere tidspunkt.',
    },
    authorization: 'Autoriseringsfeil',
    authTimeOut: 'Autentiseringsprosedyren brukte for lang tid',
    booking: {
      cancellationFailed: 'Kunne ikke avbestille reisen',
      getFailed: 'Kunne ikke hente detaljer for reisen. Vennligst forsøk å laste inn siden på nytt.',
      updateFailed: 'Kunne ikke oppdatere reisen',
      cancelled: 'Reisen er kansellert',
      notFound: 'Kunne ikke finne reisen',
      pdfFailed: 'Kunne ikke hente reisebekreftelse',
      resendPdfFailed: 'Kunne ikke sende reisebekreftelse',
      regnumFailed: 'Kunne ikke oppdatere registreringsnummer',
      travelerFailed: 'Kunne ikke oppdatere passasjerinformasjon',
      invalid: 'Reisen du prøver å hente er ugyldig.',
      connectionError: 'Kunne ikke hente bestillinger. Vennligst prøv å laste på nytt',
      bookingListFailed: 'Vi opplevde et problem med å hente bookingene dine. Vennligst prøv igjen senere.',
      detailsNotFound: 'Vi kunne ikke laste inn reisedetaljene for reisen din',
      detailsNotFoundBookingCode: 'med bestillingskode: ',
      problemPersists: 'Hvis dette problemet vedvarer, vennligst kontakt oss eller bruk knappen Rapporter et problem for å gi oss beskjed.'
    },
    customer: {
      notFound: 'Kunne ikke finne profil',
      getFailed: 'Kunne ikke hente profil',
      createFailed: 'Kunne ikke opprette profil',
      createAlreadyExists: 'Profilen eksisterer allerede',
      updateFailed: 'Kunne ikke oppdatere profil',
      travelFriends: {
        addFailed: 'Kunne ikke legge til reisefølge',
        updateFailed: 'Kunne ikke oppdatere reisefølge',
        deleteFailed: 'Kunne ikke slette reisefølge',
      },
    },
    notify: {
      loading: 'Endring pågår...',
      success: 'Endring utført!',
      failure: 'Endring mislykkes!',
    },
    response:
      'Kunne ikke motta svar fra tjener. \nSørg for at du har nettverksforbindelse og last inn siden på nytt for å prøve igjen',
    generic: 'Noe gikk galt...',
    unchanged: 'Skjemaet inneholdt ingen endringer',
    unknown: 'Ukjent feil',
    gofish: 'Dra til sjøs!',

    basket: {
      generic: 'Noe gikk galt... Kunne ikke fullføre betaling. Venligst prøv igjen senere',
      or: 'eller',
      contactCustomerService: 'kontakt kundeservice om problemet vedvarer.',
      rollback: 'Noe gikk galt, og kunne ikke tømme handlekurv. Vennlgist prøv igjen, eller kontakt kundeservice om problemet vedvarer.',
      refused: 'Betalingen ble avvist. Sjekk kortinformasjonen og prøv igjen, eller kontakt oss om problemet vedvarer.',
      help: 'Trenger du hjelp? Kontakt kundeservice her',
      refused_headline: 'Betalingen ble avvist',
    }

  },
  ///////////////////////////LABEL//////////////////////
  label: {
    allFieldsRequired: '* Alle felter må fylles ut',
    booking: {
      header: 'Mine reiser',
      code: 'Bookingnummer',
      resendPDF: 'Send reisedokumenter på epost',
      resendFailed: 'Sending feilet',
      noBookings: 'Du har ingen kommende reiser.',
      upcomingBooking_plural: 'Du har {{count}} kommende reiser',
      noActiveBookings: 'Du har ingen aktive reiser.',
      activeBooking_plural: 'Du har {{count}} aktive reiser',
      upcomingBooking: 'Du har en kommende reise',
      activeBooking: 'Du har en aktiv reise',
      makeBooking: 'Bestill din neste reise i dag',
      makeNewBooking: 'Bestill ny reise',
      active: 'Aktiv',
      future: 'Fremtidig',
      cancelled: 'Avbestilt',
      unavailable: 'Utilgjengelig',
      pastBooking: 'Gjennomført reise',
      showCancelled: 'Vis avbestilte',
      showPassed: 'Vis passerte reiser',
      hidePassed: 'Skjul passerte reiser',
      hideCancelled: 'Skjul avbestilte',
      cancelValidTime: 'Du kan kansellere turen opptil 24 timer før avreise',
      paymentDone: 'Betaling gjennomført!',
      paymentMessage: 'Takk for ditt kjøp! Produktene du har kjøpt vil være synlig i din booking oversikt.',
      basketInfo: {
        yourBasket: 'Din handlekurv',
        info: 'Gå til handlekurven for å gjennomføre eller slette endringene på turen',
        toPay: 'Å betale: ',
      },
    },
    boolean: {
      false: 'Nei',
      true: 'Ja',
    },
    button: {
      add: 'Legg til',
      addMore: 'Legg til mer',
      addMoreItems: 'Legg til varer på reisen',
      noItems: 'Ingen varer tilgjengelig på denne reisen',
      addMoreExtraOnboard: 'Legg til ekstra ombord',
      addMoreExtraAshore: 'Legg til ekstra på land',
      back: 'Tilbake',
      backToBookings: 'Tilbake til mine reiser',
      cancel: 'Avbryt',
      close: 'Lukk',
      closeAlert: 'Lukk varsel',
      confirm: 'Bekreft',
      delete: 'Slett',
      deselectAll: 'Fjern alle',
      edit: 'Endre',
      gotoBasket: 'Gå til handlekurv',
      join: 'Bli medlem gratis!',
      loading: 'Laster...',
      moreInfo: 'Åpne reisedetaljer',
      resetChanges: 'Tilbakestill endringer',
      save: 'Lagre',
      saveChanges: 'Lagre endringer',
      selectAll: 'Velg alle',
      submit: 'Send inn',
      show: 'Vis',
      ACTIVE_AND_FUTURE: 'Aktive og fremtidige',
      FUTURE: 'Fremtidige ',
      ACTIVE: 'Aktive ',
      CANCELLED: 'Kansellerte ',
      PASSED: 'Passerte ',
      bookingOverview: 'Gå til booking oversikt',
      downloadPDF: 'Last ned reisebekrefelse (PDF)'
    },
    country: CarRes.country,
    currency: {
      NOK: '{{amount}} kr',
      DKK: '{{amount}} DKK',
      EUR: '€{{amount}}',
    },
    date: {
      day: 'Dag',
      month: 'Måned',
      month_1: 'Januar',
      month_2: 'Februar',
      month_3: 'Mars',
      month_4: 'April',
      month_5: 'Mai',
      month_6: 'Juni',
      month_7: 'Juli',
      month_8: 'August',
      month_9: 'September',
      month_10: 'Oktober',
      month_11: 'November',
      month_12: 'Desember',
      selectDay: 'Velg dag',
      selectMonth: 'Velg måned',
      selectYear: 'Velg år',
      year: 'År',
    },
    gender: {
      F: 'Kvinne',
      M: 'Mann',
    },
    journey: {
      arrival: 'Ankomst',
      arrivalJourney: 'Returreise',
      arrivalPort: 'Ankomsthavn',
      arrivalTime: 'Ankomsttid',
      departure: 'Avgang',
      departureJourney: 'Avreise',
      departurePort: 'Avgangshavn',
      departureTime: 'Avgangstid',
      product: 'Billettype',
      stretch: 'Strekning',
    },
    customer: {
      allowContact: 'Tillat å bli kontaktet',
      countryCode: 'Landskode',
      currency: 'Valuta',
      customerCode: 'Kunde ID',
      customerGroup: 'Dine interesser',
      language: 'Språk',
      name: 'Navn',
      travelFriend: 'Reisefølge',
      consents: {
        header: 'Samtykker',
        pretext:
          'Jeg samtykker i Fjord Clubs vilkår og betingelser og godtar at Fjord Line lagrer mine opplysninger til følgende formål:',
        posttext:
          'Fjord Line bruker kun opplysningene for å gi deg en bedre opplevelse og de deles ikke med noen tredjepart.',
        EMAIL: 'Sende e-post, brukerprofilering, kjøpsopplysninger',
        MAIL: 'Sende post',
        SMS: 'Sende SMS',
      },
    },
    membership: {
      header: 'Medlemsinformasjon',
      memberNumber: 'Medlemsnummer',
      membershipLevel: 'Medlemsnivå',
      memberSince: 'Medlem siden',
      loyaltyPointsEarned: 'Totalt opptjente poeng',
      loyaltyPointsBalance: 'Poeng',
      loyaltyPointsSoonExpiring: 'Poeng som snart utløper',
    },
     signedUp:  {
      goToMyTravels: 'Gå til Mine reiser',
      seeMembership : 'Se medlemskap',
    },
    personalia: {
      code: 'kode',
      personalData: 'Personopplysninger',
      dateOfBirth: 'Fødselsdato',
      email: 'E-post',
      firstName: 'Fornavn',
      gender: 'Kjønn',
      header: 'Personopplysninger',
      lastName: 'Etternavn',
      mobile: 'Mobilnummer',
      mobileWord: 'tekstmelding',
      receiveNewVerificationCode: 'Motta ny bekreftelseskode',
      verificationCode: 'Bekreftelseskode',
      verificationCompleted: '{{field}} er bekreftet',
      sendingVerificationCode: 'Sender bekreftelseskode...',
      verificationModalMessage: 'Skriv inn bekreftelseskode mottatt i {{field}}.',
      verificationWillExpireMessage: 'Etter {{minutes}} minutter må du bekrefte på nytt',
      verificationRequired: 'Endret {{field}} må bekreftes',
      verify: 'Bekreft {{value}}',
      verifyErrorMessage: 'Angitt kode kan ikke bekreftes',
      tpoCannotSendCodeMessage: 'Kunne ikke sende engangskode. Vennligst prøv på nytt.',
    },
    address: {
      header: 'Adresse',
      address: 'Adresse',
      zip: 'Postnummer',
      city: 'By',
      country: 'Land',
      nationality: 'Nasjonalitet',
    },
    traveler: {
      edit: 'Endre reisende',
      header: 'Reisende',
      disabledPerson: 'Særskilt behov / trenger assistanse',
      guestType: 'Billettype',
      passportNumber: 'ID-nummer',
    },
    travelFriend: {
      header: 'Mitt reisefølge',
      add: 'Legg til reisefølge',
      delete: 'Slett reisefølge',
      edit: 'Endre reisefølge',
      find: 'Eksisterende medlem',
      fill: 'Fyll inn informasjon',
      new: 'Nytt reisefølge',
      notEditable: 'Dette reisefølget er et Fjord Club medlem og kan ikke endres.',
      birthDate: 'Fødselsdato',
      nationality: 'Nasjonalitet',
      tempDisabled: 'Reisevenner blir snart tilgjengelig',
    },
    unknown: 'Ukjent',
    vehicle: {
      edit: 'Endre kjøretøy',
      header: 'Kjøretøy',
      category: 'Biltype',
      length: 'Lengde',
      length_upto: 'Opptil {{length}} m lang',
      regNum: 'Registreringsnummer',
      code: {
        vehicle195: 'Bil inntil 1,95 m høy',
        vehicle220: 'Bil inntil 2,20 m høy',
        vehicle235: 'Bil inntil 2,35 m høy',
        vehicle450: 'Bil inntil 4,50 m høy',
        bik: 'Sykkel',
        mc: 'Motorsykkel',
        mcs: 'Motorsykkel med sidevogn',
      }
    },
    preferences: {
      header: 'Reise',
      portOfDeparture: 'Foretrukket utreisehavn',
    },
    tickets: {
      header: 'Billetter',
      sendToEmail: 'Send til e-post',
      sendToEmailAgain: 'Send en gang til',
      departureDate: 'Avgangsdato:',
      resentTickets: 'Billettene har blitt sendt til din e-post',
      resendTicketsFailed: 'Vi beklager, men det ser ut til at vi ikke kan sende billettene dine pånytt for øyeblikket. Vennligst prøv igjen senere. Dersom problemet vedvarer, vennligst kontakt kundeservice for assistanse',
      resendTimeout: 'Kan sendes pånytt om {{seconds}} sekunder',
      ticketError: 'Vi beklager, men det ser ut til at vi ikke kan hente billettene dine for øyeblikket. Vennligst prøv igjen senere. Dersom problemet vedvarer, vennligst kontakt kundeservice for assistanse',
      ticketError_delay: 'Vær oppmerksom på at dersom bestillingen nettopp ble gjennomført, kan det ta noen minutter før billettene blir tilgjengelige på denne siden.',
      barcode: {
        alt: 'barcode',
      },
    },
  },
  /////END OF LABEL SECTION/////
  birthDate: {
    day: 'Dag',
    day_placeholder: 'Velg fødselsdag',
    month: 'Måned',
    month_placeholder: 'Velg fødselsmåned',
    year: 'År',
    year_placeholder: 'Velg fødselsår',
  },
  placeholder: {
    personalia: {
      firstName: 'Ditt fornavn',
      lastName: 'Ditt etternavn',
      gender: 'Velg kjønn',
      email: 'Din e-postadresse',
      mobile: 'Ditt telefonnummer',
      mobileNoCC: 'Ditt telefonnummer',
    },
    address: {
      address: 'Din adresse',
      zip: 'Ditt postnummer',
      city: 'Ditt hjemsted',
      country: 'Velg land',
      nationality: 'Velg nasjonalitet',
    },
    traveler: {
      passportNumber: 'Ditt passnummer',
    },
    vehicle: {
      regNum: 'Ditt registreringsnummer',
    },
    preferences: {
      portOfDeparture: 'Velg din foretrukne utreisehavn',
    },
  },
  validation: {
    isRequired: 'Dette feltet er påkrevd',
    memberNumber: 'Medlemsnummer må være 7 eller 10 siffer',
    regNumber: 'Registreringsnummer må være minimum 2',
    isAlphabetic: 'Dette feltet skal kun inneholde bokstaver',
    isAlphanumeric: 'Dette feltet skal kun inneholde bokstaver og tall',
    isDate: 'Valgt dato er ikke gyldig',
    isDateInFuture: 'Valgt dato er ikke en gyldig fødselsdato',
    isGuestType: 'Fødselsdatoen samsvarer ikke med billettens aldersbegrensninger',
    isEmail: 'Dette er ikke en gyldig e-postadresse',
    isMobile: 'Dette er ikke et gyldig telefonnummer',
    isCountryCode: 'Dette er ikke en gyldig landskode',
    isOneTimeCode: 'Dette er ikke en gyldig engangskode',
    isOwnCustomerCode: 'Kan ikke bruke din egen kunde ID',
    isNumeric: 'Dette feltet skal kun inneholde tall',
    isPostalCode: 'Dette feltet er ikke et gylid postnummer',
    hasDigits: 'Dette feltet skal ikke inneholde tall',
    hasLetters: 'Dette feltet skal ikke inneholde bokstaver',
    isRegnumber: 'Dette er ikke et gyldig registreringsnummer',
    hasCountryCode: 'Landskode er påkrevd (+47)',
    cannotChangeUserNameMessage: 'Feltet kan ikke endres fordi dette er din brukerid på når du logger inn',
    missingRegNum: 'Registreringsnummer mangler for dette kjøretøyet',
  },
  ports: {
    LA: 'Langesund',
    B: 'Bergen',
    HH: 'Hirtshals',
    S: 'Stavanger',
    SS: 'Strømstad',
    K: 'Kristiansand',
    SA: 'Sandefjord',
    ST: 'Strömstad',
    ALB: 'Aalborg'
  },
  stepperInputWrapper: {
    selectValidValue: 'Velg antall mellom',
    validValuesFrom: 'Antall fra {{from}} til {{to}}',
    validValuesTo: ' til {{to}}',
  },

  payment: {
    partlyPaid: 'Delvis betalt',
    partlyPaid_description: '',
    fullyPaid: 'Betaling gjennomført',
    fullyPaid_description: '',
    declined: 'Betaling avvist',

  },
  icons: {
    seaView: 'Havutsikt',
    shower: 'Dusj',
    sofaBed: 'Sovesofa',
    doubleBed: 'Dobbeltseng',
    roomForCrib: 'Plass til barneseng',
  },

  payWithFjordClubPoints: {
    headline: 'Betal med Fjord Club poeng',
    ingress: 'Du har {{points}} poeng tilgjengelig. Du kan bruke poengene dine til å betale/delbetale for denne reisen.',
    readMore: 'Les mer om denne reisen',
    bookNow: 'Bestill nå',
    navBar: 'Fjord Club reiser',
    from: 'Fra',
    EuTaxLabel: 'per person + EU miljøavgift'
  },

  modificationDeadline: {
    seeWhatCloses: 'Se hva som stenger',
    timerText: 'Endringsfrist for denne reisen utløper om ',
    modalHeadline:'Endringsmuligheter som stenger',
    deadlineIsPastModal: 'Endringsfristen for denne reisen har utløpt.',
  }

}

export type Translation = typeof translation
export type PartialTranslation = DeepPartial<Translation>
