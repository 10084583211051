import styled from 'styled-components'
import { Colors } from '@fjordline/styles-v3'

// Missing in fjordline/styles-v3
export const StyledTextArea = styled.textarea`
  appearance: none;
  display: 'block';
  font-size: 1.125rem;
  border: 1px solid ${Colors.GRAY};
  border-radius: 5px;
  padding: 1rem 0.9375rem;
  color: ${Colors.GRAY_DARKEST};
  outline: none;
  width: 100%;
  resize: none;

  &:hover {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
    border-color: ${Colors.GRAY_DARK};
  }
`
