import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Accordion, AccordionItem, Column, H2, Row, useAccordionState } from '@fjordline/styles-v3'
import styled from 'styled-components'

import StepperStateWrapper from '../../../../components/StepperStateWrapper'
import UseGetBookingDetails from '../../../../graphql/customerHooks/UseGetBookingDetails'
import { AvailabilityExtraItemWithSpec, Specification } from '../../../../graphql/types'
import { useAvailabilityItems, useAvailabilityItemsOperations } from '../../../../providers/AvailabilityItemsProvider'
import { LessPadding } from '../addExtrasOnboard/ExtrasOnboard'
import { findPassengerQuantityPerFareCode } from '../meals/utils'
import useFetchExtras from '../../../../sanity/extras/useFetchExtras'
import { PortableText } from '@portabletext/react'
import { StyledPortableText, _blankAnchor } from '../../../../sanity/components/PortableTextComponent'
import useFetchSpecifications from '../../../../sanity/specifications/useFetchSpecifications'

type ExtrasProps = {
  isOutbound: boolean
  data: AvailabilityExtraItemWithSpec[]
}

const ExtrasAshore: React.FC<ExtrasProps> = ({ data, isOutbound }: ExtrasProps) =>
{
  const { openedItems, onToggle, onToggleAll } = useAccordionState({ multiOpen: true })
  const { bookingCode } = useParams()
  const { addToCartState } = useAvailabilityItems()
  const itemsInState = addToCartState?.[ bookingCode || '' ]?.[ isOutbound ? 'outbound' : 'inbound' ]?.extrasAshore
  const isOnboard = false
  const { t } = useTranslation()

  const checkedItems = itemsInState?.map((item) =>
  {
    return `code: ${item.code}`
  })

  return (
    <Row>
      <Column>
        {' '}
        <H2 style={{ marginTop: '1rem', marginBottom: '1rem' }}>
          {isOutbound ? t('component.general.departure') : t('component.general.return')}
        </H2>
      </Column>

      <LessPadding>
        <Accordion
          onToggleAll={onToggleAll}
          variant="panel"
          onToggle={onToggle}
          openedItems={openedItems}
          values={checkedItems}
        >
          {data.map((item) =>
          {
            return <ExtraAccordionItem key={item.code} item={item} isOutbound={isOutbound} isOnboard={isOnboard} />
          })}
        </Accordion>
      </LessPadding>
    </Row>
  )
}

export default ExtrasAshore

export const ExtraItemReadMoreContainer = styled.div`
  a {
    font-weight: 500;
    text-decoration: underline;
  }
`
type ExtraAccordionItemProps = {
  item: AvailabilityExtraItemWithSpec
  isOutbound: boolean
  isOnboard: boolean
}
export const ExtraAccordionItem: React.FC<ExtraAccordionItemProps> = ({
  item,
  isOutbound,
  isOnboard,
}: ExtraAccordionItemProps) =>
{
  const { openedItems, onToggle, onToggleAll } = useAccordionState({ multiOpen: true })
  const { bookingCode } = useParams()
  const { addToCartState } = useAvailabilityItems()
  const itemsInState = addToCartState?.[ bookingCode || '' ]?.[ isOutbound ? 'outbound' : 'inbound' ]?.extrasAshore
  const { addExtrasToCart } = useAvailabilityItemsOperations()
  const { flBooking } = UseGetBookingDetails({ bookingCode: bookingCode || '' })
  const { children, baby, infants } = findPassengerQuantityPerFareCode(flBooking?.outbound?.passengers || [])
  const checkedAccordionItem = itemsInState?.map((item) =>
  {
    return `AccordionItem: code: ${item.code} subCode: ${item.subCode}`
  })

  const { findExtrasName, findImageUrl, findExtrasFullDescription } = useFetchExtras(item.code)


  if (item.specifications?.every((e) => e.hidePrice === true)) return null

  return (
    <AccordionItem key={item.code} title={findExtrasName() || item.itemName} itemId={`code: ${item.code}`}>
      <Accordion
        data-cy={`accordion-${item.code}`}
        variant="checkbox"
        values={checkedAccordionItem}
        openedItems={openedItems}
        onToggle={onToggle}
        onToggleAll={onToggleAll}
        onChange={(e) =>
        {
          if (e && typeof e === 'string') {
            const specCode = extractSubCode(e)
            const spec = item.specifications?.find((a) => a.specificationCode === specCode)
            if (spec) {
              if (itemsInState?.find((e) => e.code === item.code && e.subCode === specCode)) {
                addExtrasToCart(item, 0, spec, bookingCode || '', isOutbound, isOnboard)
              } else {
                addExtrasToCart(item, 1, spec, bookingCode || '', isOutbound, isOnboard)
              }
            }
          }
        }}
      >
        <Row style={{ marginBottom: '1rem' }}>
          <Column large={4} medium={4}>
            <img
              width="100%"
              src={findImageUrl()}
              alt={findExtrasName() || item.code}
              style={{ borderRadius: '4px', maxHeight: '300px' }}
            />
          </Column>
          <Column large={8} medium={8}>
            <StyledPortableText>
              <PortableText value={findExtrasFullDescription()} components={_blankAnchor} />
            </StyledPortableText>
          </Column>
        </Row>
        {item.specifications?.map((spec) =>
        {
          if (spec.specificationCode === 'CHD' && children === 0) return null
          if (spec.specificationCode === 'INF' && infants === 0) return null
          if (spec.specificationCode === 'BABY' && baby === 0) return null
          return (
            <ExtraAshoreSpec
              key={spec.specificationCode}
              spec={spec}
              item={item}
              isOutbound={isOutbound}
              isOnboard={false}
            />
          )
        })}
      </Accordion>
    </AccordionItem>
  )
}

function findMaxToBook(
  specs,
  adultPassengerCount,
  childPassengerCount,
  infantPassengerCount,
  babyPassengerCount,
  items,
)
{
  let maxToBook

  switch (specs.specificationCode) {
    case 'ADL':
      maxToBook = adultPassengerCount
      adultPassengerCount > specs.maximumQuantityToBook
        ? (maxToBook = specs.maximumQuantityToBook)
        : (maxToBook = adultPassengerCount)
      break
    case 'CHD':
      maxToBook = childPassengerCount
      childPassengerCount > specs.maximumQuantityToBook
        ? (maxToBook = specs.maximumQuantityToBook)
        : (maxToBook = childPassengerCount)
      break
    case 'INF':
      maxToBook = infantPassengerCount
      infantPassengerCount > specs.maximumQuantityToBook
        ? (maxToBook = specs.maximumQuantityToBook)
        : (maxToBook = infantPassengerCount)
      break
    case 'BABY':
      maxToBook = babyPassengerCount
      babyPassengerCount > specs.maximumQuantityToBook
        ? (maxToBook = specs.maximumQuantityToBook)
        : (maxToBook = babyPassengerCount)
      break
    default:
      maxToBook =
        adultPassengerCount + childPassengerCount <= specs.maximumQuantityToBook
          ? adultPassengerCount + childPassengerCount + infantPassengerCount + babyPassengerCount
          : specs.maximumQuantityToBook
      break
  }
  // to do - not hard code
  if (items.code === 'INTE') {
    maxToBook = adultPassengerCount * 3
  }

  if (!specs.specificationPrice.available) {
    maxToBook = 0
  }

  return maxToBook
}

export function extractSubCode(inputString: string): string | null
{
  const regex = /subCode:\s+(\w+)/
  const match = inputString.match(regex)
  if (match && match[ 1 ]) {
    return match[ 1 ]
  } else {
    return null
  }
}
type ExtraAshoreSpecProps = {
  spec: Specification
  item: AvailabilityExtraItemWithSpec
  isOutbound: boolean
  isOnboard: boolean
}
const ExtraAshoreSpec: React.FC<ExtraAshoreSpecProps> = ({ spec, item, isOutbound, isOnboard }) =>
{
  const { bookingCode } = useParams()
  const { addToCartState } = useAvailabilityItems()
  const itemsInState = addToCartState?.[ bookingCode || '' ]?.[ isOutbound ? 'outbound' : 'inbound' ]?.extrasAshore
  const { addExtrasToCart } = useAvailabilityItemsOperations()
  const { i18n } = useTranslation()
  const { flBooking } = UseGetBookingDetails({ bookingCode: bookingCode || '' })
  const { adults, children, baby, infants } = findPassengerQuantityPerFareCode(flBooking?.outbound?.passengers || [])
  const { specByCode } = useFetchSpecifications(spec.specificationCode)

  if (spec.hidePrice) return null

  return (
    <AccordionItem
      key={spec.specificationCode}
      title={specByCode?.specificationName?.[ i18n.language ]}
      data-cy={`accordion-item-${spec.specificationCode}`}
      itemId={
        findMaxToBook(spec, adults, children, infants, baby, item) === 0
          ? ''
          : `AccordionItem: code: ${item.code} subCode: ${spec.specificationCode}`
      }
    >
      <Row>
        <Column key={spec.specificationCode}>
          <StepperStateWrapper
            data-cy={`stepper-${spec.specificationCode}`}
            id={spec.specificationCode || ''}
            label={specByCode?.specificationName?.[ i18n.language ]}
            disabled={findMaxToBook(spec, adults, children, infants, baby, item) === 0}
            inputAsText={true}
            min={0}
            allowZero={true}
            max={findMaxToBook(spec, adults, children, infants, baby, item)}
            value={
              itemsInState?.find((m) => m.code === item.code && m.subCode === spec.specificationCode)?.quantity || 0
            }
            setValue={(value) =>
            {
              addExtrasToCart(item, value, spec, bookingCode || '', isOutbound, isOnboard)
            }}
            price={spec.hidePrice ? undefined : {
              amount: spec.specificationPrice.value || 0,
              currency: spec.specificationPrice.currency || '',
              locale: i18n.language,
            }}
          />
        </Column>
      </Row>
    </AccordionItem>
  )
}
