import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { Button, Column, Divider, FormElementWrapper, Label, Row, StepperInput, TrashLight } from '@fjordline/styles-v3'

import { StepperInputWrapper } from '../../components/StepperStateWrapper'
import { Specification } from '../../graphql/types'
import { useAvailabilityItems, useAvailabilityItemsOperations } from '../../providers/AvailabilityItemsProvider'
import { useWebSocketContext } from '../../providers/myPageStateProvider/websocketProvider/websocketContext'
import { extractBookingNumber } from '../../providers/WebsocketProvider'
import { IExtras } from '../../types/myPage/customerBookingDetails/types'
import { WithImageLeft, MealImage, Grow, LowerButton, DividerWrapper } from './meals/meals.styles'
import useFetchExtras from '../../sanity/extras/useFetchExtras'
import { imageUrlFor } from '../../sanity/imageUrlBuilder'
import useFetchSpecifications from '../../sanity/specifications/useFetchSpecifications'

type Props = {
  isOutbound: boolean
}

const ExtrasOnboard: React.FC<Props> = ({ isOutbound }: Props) =>
{
  const location = useLocation()
  const bookingCode = extractBookingNumber(location)
  const { addToCartState } = useAvailabilityItems()
  const itemsInState = addToCartState?.[ bookingCode || '' ]?.[ isOutbound ? 'outbound' : 'inbound' ]?.extrasOnboard

  if (itemsInState) {
    return transformExtraItems(itemsInState).map((item: extraItemType) =>
    {
      return <ExtraItem key={item.code} isOutbound={isOutbound} item={item} isOnboard={true} />
    })
  } else {
    return null
  }
}

export default ExtrasOnboard

type extraItemType = {
  code: string
  spec: Specification[]
}

export type extraItemProps = {
  item: extraItemType
  isOutbound: boolean
  isOnboard: boolean
}

export const ExtraItem: React.FC<extraItemProps> = ({ item, isOutbound, isOnboard }: extraItemProps) =>
{
  const location = useLocation()
  const bookingCode = extractBookingNumber(location)
  const { t, i18n } = useTranslation()
  const direction = isOutbound ? t('component.extras.common.departure') : t('component.extras.common.departure')
  const { addToCartState } = useAvailabilityItems()
  const itemsInState =
    addToCartState?.[ bookingCode || '' ]?.[ isOutbound ? 'outbound' : 'inbound' ][
    isOnboard ? 'extrasOnboard' : 'extrasAshore'
    ]
  const { extraFromSanity } = useFetchExtras(item.code)


  return (
    <div key={item.code}>
      <Row>
        <Column>
          <FormElementWrapper>
            <WithImageLeft>
              {extraFromSanity?.extraImages?.[ 0 ].asset._ref ? (
                <MealImage
                  alt={extraFromSanity?.extraName?.[ i18n.language ]}
                  src={imageUrlFor(extraFromSanity?.extraImages?.[ 0 ]?.asset._ref)?.url()}
                />
              ) : null}

              <Grow>
                <div>
                  <Label>{extraFromSanity?.extraName?.[ i18n.language ]}</Label>
                </div>
                <div>{`${direction}`}</div>
              </Grow>
            </WithImageLeft>
          </FormElementWrapper>
        </Column>
      </Row>
      <Row>
        {item.spec.map((spec: Specification) =>
        {
          const value =
            itemsInState?.find((m) => m.code === item.code && m.subCode === spec.specificationCode)?.quantity || 0
          return (
            <SpecItem
              key={spec.specificationCode}
              value={value}
              spec={spec}
              isOutbound={isOutbound}
              item={item}
              isOnboard={isOnboard}
            />
          )
        })}
      </Row>
      <Row>
        <Column medium={12} large={12}>
          <DividerWrapper>
            <Divider />
          </DividerWrapper>
        </Column>
      </Row>
    </div>
  )
}

function transformExtraItems(extras: IExtras[])
{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const items = [] as any

  for (const item of extras) {
    const { subCode, price } = item

    const specification = {
      specificationCode: subCode,
      specificationPrice: {
        value: price.value,
        currency: price.currency,
      },
    }

    const existingItem = items?.find((i) => i.code === item.code)
    if (existingItem) {
      existingItem.spec.push(specification)
    } else {
      items.push({
        startTime: item.startTime,
        code: item.code,
        spec: [ specification ],
      })
    }
  }

  return items
}
export type SpecItemProps = {
  spec: Specification
  item: extraItemType
  isOutbound: boolean
  value: number
  isOnboard: boolean
}
export const SpecItem: React.FC<SpecItemProps> = ({ spec, item, isOutbound, value, isOnboard }: SpecItemProps) =>
{
  const { i18n } = useTranslation()
  const { cartData } = useWebSocketContext()
  const location = useLocation()
  const bookingCode = extractBookingNumber(location)
  const { addExtrasToCart } = useAvailabilityItemsOperations()
  const hasPaymentConfig = cartData?.bookingCarts?.[ bookingCode || '' ]?.bookingResult?.paymentConfiguration
    ? true
    : false
  const { specByCode } = useFetchSpecifications(spec.specificationCode)

  return (
    <>
      <Column large={11.5} medium={11.5} small={10}>
        <StepperInputWrapper>
          <StepperInput
            label={specByCode?.specificationName?.[ i18n.language ] || spec.specificationCode}
            max={spec.maximumQuantityToBook}
            value={value}
            disabled
            inputAsText
            price={spec.hidePrice ? undefined : {
              amount: spec.specificationPrice.value * value,
              currency: spec.specificationPrice.currency,
              locale: i18n.language,
            }}
            setValue={(value) =>
            {
              addExtrasToCart(item, value, spec, bookingCode || '', isOutbound, isOnboard)
            }}
            min={1}
            direction="row"
          />
        </StepperInputWrapper>
      </Column>
      {hasPaymentConfig ? null : (
        <Column large={0.5} medium={0.5} small={2}>
          <LowerButton>
            <Button
              onClick={() =>
              {
                addExtrasToCart(item, 0, spec, bookingCode || '', isOutbound, isOnboard)
              }}
              dataCy="remove-extras-onboard"
              theme="clean"
              size="small"
              icon={TrashLight}
            />
          </LowerButton>
        </Column>
      )}
    </>
  )
}
