import React, { JSX } from 'react'
import { useTranslation } from 'react-i18next'
import { Divider, FormElementWrapper, HeaderWithIcon, Label, Tax } from '@fjordline/styles-v3'

import { Extra } from '../../graphql/types'

import { MinSideContainer } from './booking.styles'
import { ExtraAshoreLabel } from './sectionExtrasAshore/extrasAshore/extrasAshore.styles'

export default function SectionTaxesAndFees({
  inboundEtax,
  outboundEtax,
}: {
  inboundEtax: Extra | null
  outboundEtax: Extra | null
}): JSX.Element | null {
  const { t } = useTranslation()

  return (
    <div style={{ marginTop: '1rem' }} data-cy="section-taxesAndFees">
      <HeaderWithIcon icon={Tax}>{t('component.extras.taxesAndFees.headline')}</HeaderWithIcon>
      <MinSideContainer>
        {outboundEtax ? (
          <>
            <FormElementWrapper dataCy="extras-onboard">
              <Label size="large">{t('component.extras.common.departure')}</Label>
            </FormElementWrapper>
            <p style={{ margin: '0', marginTop: '-0.5rem' }}>{t('component.extras.taxesAndFees.envTax')}</p>
            <ExtraAshoreLabel>{t('component.extras.misc.qty', { count: outboundEtax.quantity })}</ExtraAshoreLabel>{' '}
          </>
        ) : null}
        {inboundEtax ? (
          <>
            {outboundEtax && <Divider style={{ marginBottom: '1rem' }} />}
            <FormElementWrapper dataCy="extras-onboard">
              <Label size="large">{t('component.extras.common.arrival')}</Label>
            </FormElementWrapper>
            <p style={{ margin: '0', marginTop: '-0.5rem' }}>{t('component.extras.taxesAndFees.envTax')}</p>
            <ExtraAshoreLabel>{t('component.extras.misc.qty', { count: inboundEtax.quantity })}</ExtraAshoreLabel>
          </>
        ) : null}
      </MinSideContainer>
    </div>
  )
}
