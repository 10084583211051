import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Location, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useToastDispatcher } from '@fjordline/styles-v3'
import { HubConnectionState } from '@microsoft/signalr'

import { logDev } from '../components/LogDev'
import { ENVIRONMENT } from '../config'
import { FlBooking, FlCustomer, useGetBookingLazyQuery, useGetBookingsQuery } from '../graphql/types'
import { defaultMpCarts } from '../types/myPage/types'

import { useMyPageOperations } from './myPageStateProvider/context'
import signalREvents from './myPageStateProvider/websocketProvider/signalREvents'
import useWebsocket from './myPageStateProvider/websocketProvider/useWebsocket'
import { MpCartsContext, websocketContext } from './myPageStateProvider/websocketProvider/websocketContext'
import WebsocketOperationsProvider from './myPageStateProvider/websocketProvider/WebsocketOperationsProvider'
import {
  subscribeFlCustomerUpdated,
  subscribeToCartUpdated,
} from './myPageStateProvider/websocketProvider/websocketProviderFunctions/subscribeToEvents'
import subscribeToExceptionEvents from './myPageStateProvider/websocketProvider/websocketProviderFunctions/subscribeToExceptionEvents'
import { mpCart } from './myPageStateProvider/websocketProvider/websocketProviderFunctions/WebsocketOperationsProvider/context'
import { ContextChildren } from './genericTypes'
import { useKeycloak } from './KeycloakProvider'
import { QueryParamsEnum } from '../hooks/useQueryParams'

/**
 * @description - The provider creates a HubConnection and subscribes to available events
 * @description - The websocket connection is initiated and exposed in this provider
 * @param children - The provider wraps children of type React.ReactNode
 * @constructor
 */
const WebsocketProvider: React.FC<React.PropsWithChildren<ContextChildren>> = ({ children }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation()
  const { setCustomer } = useMyPageOperations()
  const { dispatchToast } = useToastDispatcher()
  const { authenticationError, connection } = useWebsocket()
  const [hasUpdatedBooking, setHasUpdatedBooking] = useState<boolean>(false)
  const { isAuthenticated: kc_isAUth } = useKeycloak()
  const isAuthenticated = kc_isAUth
  const { refetch: refetchBookings } = useGetBookingsQuery({
    skip: !isAuthenticated,
  })

  useEffect(() => {
    if (hasUpdatedBooking) {
      setHasUpdatedBooking(false)
      const params = new URLSearchParams(location.search)

      params.delete(QueryParamsEnum.isUpdatingPassengers)
      params.delete(QueryParamsEnum.isUpdatingRegNum)

      navigate({
        pathname: location.pathname,
        search: params.toString(),
      })

      logDev('(#24) WebsocketProvider - effect - hasUpdatedBooking', location)
    }
  }, [hasUpdatedBooking, location, navigate])

  const [getBookingByBookingCode] = useGetBookingLazyQuery({
    fetchPolicy: 'network-only',
  })

  /**
   * Local state values are set to trigger effects
   * When cartDate is set, effect with updateGlobalStateWhenCartDataChanges is triggered => update global state with setMpCarts
   * When cart is set, effect with addOrUpdateCartDataWhenCartHasValue is triggered => set cartData to trigger effect
   *
   */
  const [cart, setCart] = useState<mpCart | undefined>(undefined)
  const [cartData, setCartData] = useState<mpCart>(defaultMpCarts)
  const [flCustomer, setFlCustomer] = useState<FlCustomer | undefined>(undefined)
  const [initCart, setInitCart] = useState<mpCart | undefined>(undefined)
  const [flCustomerLoading, setFlCustomerLoading] = useState<boolean>(false)
  const [customerSuccessUpdated, setCustomerSuccessUpdated] = useState<boolean>(false)

  useEffect(
    function updateGlobalStateWhenDlCustomerChanges() {
      if (!isAuthenticated) return
      if (flCustomer !== undefined || flCustomer !== null) {
        logDev('(#25) WebsocketProvider - effect - update global state', flCustomer)
        if (flCustomer) {
          setCustomer(flCustomer)
        }
      }
    },
    [flCustomer, isAuthenticated, setCustomer],
  )

  /**
   * @description - (2) When cart is set, add or update cartData
   */
  useEffect(
    function addOrUpdateCartDataWhenCartHasValue() {
      if (cart && cart?.id) {
        setCartData({
          ...cart,
          bookingCarts: {
            ...cart?.bookingCarts,
          },
          id: cart?.id,
          timestamp: Date.now(),
        })
        sessionStorage.setItem('cartData', JSON.stringify(cart))
        setCart(undefined)
      }
    },
    [cart],
  )

  useEffect(() => {
    if (authenticationError) {
      logDev('(#18) authenticationError', authenticationError)
    }
  }, [authenticationError])

  /**
   * @description - (1) Subscribe to InitCart => expect MpCart => set local state cartData
   * @description - (2) Unsubscribe InitCart => subscribe to runtime events
   * @description - (3) cartData changes triggers an effect to update reducer state in MyPage (MyPageProvider)
   * @description . (4) The same principal applies to other events e.g. CartUpdated (triggered by UpdateCart)
   * @description - (5) on(CartUpdated) => set local state cart
   * @description - (6) cart changes triggers an effect to update cart which in turn update cartData => (3)
   * ----------------------------------------------------------------------------------------------------
   * @description - MyPage state must be updated via local state to avoid dependencies in this effect
   * @description - This effect should run executing statements only once, that is only when HubConnectionState is disconnected
   * @description - ( => if language is changed, messages will not be translated)
   */
  useEffect(
    function subscribeToEventsWhenConnectionIsReady() {
      if (connection !== null && connection.state === HubConnectionState.Disconnected) {
        logDev('==> (#3) Start websocket connection')
        connection
          .start()
          .then(() => {
            logDev('(#4)websocket connection started', connection.connectionId)
            subscribeToExceptionEvents({ connection, dispatchToast, isDev: ENVIRONMENT === 'DEV', t })
              .then(() => {
                logDev('(#5) subscribed to exception events')
                connection.on(signalREvents.initCart, (cartData) => {
                  sessionStorage.setItem('initCart', JSON.stringify(cartData))
                  setInitCart(cartData)
                  setCartData(cartData)
                  connection.off(signalREvents.initCart)
                  if (cartData) {
                    logDev('(#6) cartData from init', cartData)
                    subscribeToCartUpdated({
                      connection,
                      setCart: (mpCart: mpCart) => setCart(mpCart),
                    })
                  }
                })

                subscribeFlCustomerUpdated({
                  connection,
                  setFlCustomerLoading: (isLoading: boolean) => setFlCustomerLoading(isLoading),
                  setCustomerSuccessUpdated: (success: boolean) => setCustomerSuccessUpdated(success),
                  setFjordClubMembership: (flCustomer: FlCustomer) => setFlCustomer(flCustomer),
                })

                connection.on(signalREvents.flCustomerUpdateFailed, (event) => {
                  logDev('(#19)flCustomerUpdateFailed', event)
                  setFlCustomerLoading(false)
                  setCustomerSuccessUpdated(false)
                  dispatchToast({
                    message: event?.message || 'Something went wrong... Could not update customer',
                    timeout: 5000,
                  })
                })

                connection.on(signalREvents.initCartFailed, (noe) => {
                  logDev('(#15)', 'init cart failed', noe)
                })
                connection.on(signalREvents.updatedBooking, (updatedBooking: FlBooking) => {
                  logDev('(#14)updatedBooking', updatedBooking)
                  refetchBookings()
                  if (updatedBooking.bookingCode) {
                    getBookingByBookingCode({
                      variables: {
                        bookingCode: updatedBooking.bookingCode,
                      },
                    })
                  }

                  if (updatedBooking && updatedBooking.totalPrice) {
                    setHasUpdatedBooking(true)
                  }
                })
                connection.on(signalREvents.carresBookingEvent, (carresBookingEvent) => {
                  logDev('(#16)carresBookingEvent', carresBookingEvent)
                })
              })
              .catch((noe) => {
                logDev('(#16)subscribeToExceptionEvents', 'failed', noe)
              })
          })
          .catch((error) => {
            logDev('(#7) Websocket connection error', error)
          })
      }
    },
    [connection, dispatchToast, getBookingByBookingCode, refetchBookings, t],
  )

  const cartDataContext: MpCartsContext = useMemo(
    function gatherValueForWebsocketContext() {
      return {
        cartData,
        connection,
        setCart,
        setCartData,
        initCart,
        flCustomerLoading,
        setFlCustomerLoading,
        customerSuccessUpdated,
        setCustomerSuccessUpdated,

        hasUpdatedBooking,
      }
    },
    [cartData, connection, customerSuccessUpdated, flCustomerLoading, hasUpdatedBooking, initCart],
  )

  return (
    <websocketContext.Provider value={cartDataContext}>
      <WebsocketOperationsProvider>{children}</WebsocketOperationsProvider>
    </websocketContext.Provider>
  )
}

export default WebsocketProvider

export function extractBookingNumber(location: Location) {
  const pathname = location.pathname
  const pathSegments = pathname.split('/')
  const bookingListIndex = pathSegments?.indexOf('bookingList')
  if (bookingListIndex !== -1) {
    const bookingNumber = pathSegments?.[bookingListIndex + 1]
    return bookingNumber as string
  } else {
    return undefined
  }
}
