import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { FormElementWrapper, H2 } from '@fjordline/styles-v3'

import CustomBackButton from '../../../components/CustomBackbutton'
import { FlBooking, useGetBookingQuery } from '../../../graphql'
import { BookingListNavLink, BookingNavLink } from '../../../routes/navLinkFunctions'
import { Status } from '../../../types/myPage/enums'

import EditPassengerForm from './editPassenger/EditPassengerForm'
import getEditPassengerObject, { EditPassengerObject } from './editPassenger/getEditPassengerObject'

const EditPassenger: React.FC = () => {
  useMemo(() => window.scrollTo(0, 0), [])
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { bookingCode, rowNumber } = useParams()
  const { data } = useGetBookingQuery({ variables: { bookingCode: bookingCode || '   ' } })
  const booking = data?.booking
  const { isInFuture, journeyPorts, passenger } = useMemo<EditPassengerObject>(
    () =>
      getEditPassengerObject({
        flBooking: booking as FlBooking,
        passengerRowNumber: rowNumber,
      }),
    [booking, rowNumber],
  )

  const isMember = useMemo<boolean>(() => passenger?.customerCode?.length !== undefined, [passenger])

  if (bookingCode === undefined) {
    navigate(BookingListNavLink({ bookingListView: Status.FUTURE }))
  }
  if (rowNumber === undefined || passenger === undefined) {
    navigate(BookingNavLink({ bookingCode: bookingCode }))
  }

  return (
    <div style={{ maxWidth: '50rem' }}>
      <FormElementWrapper>
        <CustomBackButton title={journeyPorts} defaultBackLinkTo={BookingNavLink({ bookingCode: bookingCode })} />
      </FormElementWrapper>
      <H2>{t(`label.traveler.${!isInFuture || isMember ? 'header' : 'edit'}`)}</H2>
      <EditPassengerForm key={rowNumber} bookingCode={bookingCode} isInFuture={isInFuture} passenger={passenger} />
    </div>
  )
}

export default EditPassenger
