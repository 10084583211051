import React, { useState } from 'react'
import { Button, Warning } from '@fjordline/styles-v3'
import { styled } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { ReportIssueModal } from './Modal'
import { FORM_RECEIVER_URL } from '../../config'

export const ReportButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1rem;
  padding: 3rem 0;
`

export const ReportIssueButton: React.FC = () => {
  const [showModal, setShowModal] = useState(false)

  const { t } = useTranslation()

  if (!FORM_RECEIVER_URL) return false

  return (
    <>
      <Button leadingIcon={Warning} theme="ghost" size="large" onClick={() => setShowModal(true)} data-cy="reportIssueButton" >
        {t('component.reportIssue.button.reportIssue')}
      </Button>
      {showModal && <ReportIssueModal onClose={() => setShowModal(false)} />}
    </>
  )
}
