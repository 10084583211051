import React, { useRef, useEffect, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import '@adyen/adyen-web/dist/adyen.css'
import AdyenCheckout from '@adyen/adyen-web'
import { BookingResult } from '@fjordline/booking-draft'
import styled from 'styled-components'
import { Colors } from '@fjordline/styles-v3'
import { useAvailabilityItems, useAvailabilityItemsOperations } from '../../providers/AvailabilityItemsProvider'
import { useWebSocketContext } from '../../providers/myPageStateProvider/websocketProvider/websocketContext'
import { extractBookingNumber } from '../../providers/WebsocketProvider'
import { logDev } from '../../components/LogDev'
export const AdyenPayment = ({ bookingResult }: CheckoutProps) => {
  const { checkoutLoading, rollbackLoading } = useAvailabilityItems()

  if (!bookingResult || checkoutLoading || rollbackLoading) return null
  return (
    <div style={{ width: '100%' }} id="payment-page">
      <div className="container">
        <Checkout bookingResult={bookingResult} />
      </div>
    </div>
  )
}

type CheckoutProps = {
  bookingResult: BookingResult
}

const Checkout = ({ bookingResult }: CheckoutProps) => {
  const { i18n } = useTranslation()
  const paymentContainer = useRef(null)
  const navigate = useNavigate()
  const { setAddToCartState } = useAvailabilityItemsOperations()
  const { addToCartState } = useAvailabilityItems()
  const { cartData } = useWebSocketContext()
  const [adyenRendered, setAdyenRendered] = useState(false)

  const location = useLocation()
  const bookingCode = extractBookingNumber(location)

  const clearCartByBookingCode = useCallback(() => {
    const updatedState = {
      ...addToCartState,
      [bookingCode ?? '']: {
        outbound: [],
        inbound: [],
      },
    }
    setAddToCartState(updatedState)
  }, [addToCartState, bookingCode, setAddToCartState])

  let adyenLanguage

  switch (i18n.language) {
    case 'nb':
      adyenLanguage = 'no-NO'
      break
    case 'da':
      adyenLanguage = 'da-DK'
      break
    case 'de':
      adyenLanguage = 'de-DE'
      break
    default:
      adyenLanguage = 'en-US'
      break
  }

  useEffect(() => {
    let ignore = false

    if (!paymentContainer.current || adyenRendered) {
      // initiateCheckout is not finished yet.
      return
    }

    const createCheckout = async () => {
      const checkout = await AdyenCheckout({
        clientKey: bookingResult.paymentConfiguration?.clientKey,
        session: {
          id: bookingResult.paymentConfiguration?.sessionId,
          sessionData: bookingResult.paymentConfiguration?.sessionData,
        },

        locale: adyenLanguage,
        environment: bookingResult.paymentConfiguration?.environment,

        onPaymentCompleted: (res) => {
          logDev('onPaymentCompleted', res)
          if (res.resultCode === 'Refused') {
            navigate(`/bookingList/${bookingCode}/basket?refused=true`)
          }

          if (res.resultCode === 'Authorised') {
            sessionStorage.removeItem('bookingResult')
            if (bookingCode) {
              clearCartByBookingCode()
            }
            navigate(`/bookingList/${bookingCode}/payment?paidState=FULLY_PAID&paymentStatus=ACCEPTED&isUpdating=true`)
          }
        },
        showPayButton: true,

        onError: (error, _component) => {
          logDev(error)
        },
      })

      if (paymentContainer.current && !ignore) {
        checkout.create('dropin').mount(paymentContainer.current)
        setAdyenRendered(true)
      }
    }

    createCheckout()

    return () => {
      ignore = true
    }
  }, [
    adyenLanguage,
    adyenRendered,
    bookingCode,
    bookingResult.bookingCode,
    bookingResult.paymentConfiguration?.clientKey,
    bookingResult.paymentConfiguration?.environment,
    bookingResult.paymentConfiguration?.sessionData,
    bookingResult.paymentConfiguration?.sessionId,
    cartData.id,
    clearCartByBookingCode,
    navigate,
  ])

  return (
    <PaymentContainer className="payment-container">
      <div ref={paymentContainer} className="payment"></div>
    </PaymentContainer>
  )
}

const PaymentContainer = styled.div`
  margin-left: 5px;
  .adyen-checkout__button {
    background: ${Colors.PROFILE_RED_2024};
  }

  .adyen-checkout__input--focus {
    border: 0.5px solid ${Colors.PROFILE_RED_2024};
    box-shadow: 0 0 0 1px ${Colors.PROFILE_RED_2024};
  }

  .adyen-checkout__payment-method__radio--selected {
    background: ${Colors.PROFILE_RED_2024};
  }

  .adyen-checkout__label__text {
    color: black;
  }
`
