/* eslint-disable quotes */
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import { UAParser } from 'ua-parser-js'
import { useLocation } from 'react-router-dom'
import { FORM_RECEIVER_URL } from '../../config'
import { useKeycloak } from '../../providers/KeycloakProvider'
import { useMyPage } from '../../providers/myPageStateProvider/context'

export enum IssueType {
  MissingBookings = 'missingBookings',
  BookingDetails = 'bookingDetails',
  Other = 'other',
}

export type IssueReport = {
  issue: IssueType
  description?: string
  bookingCode?: string
  email: string
  language: string
  id: string
  shortID: string
}

export const useIssueReporter = () => {
  const { t } = useTranslation()
  const { token: kc_token } = useKeycloak()
  const { customer, fjordClubMemberStatus } = useMyPage()
  const { pathname } = useLocation()

  const IssueTypeDescription: Record<IssueType, string> = {
    [IssueType.MissingBookings]: t('component.reportIssue.issueTypes.missingBookings'),
    [IssueType.BookingDetails]: t('component.reportIssue.issueTypes.bookingDetails'),
    [IssueType.Other]: t('component.reportIssue.issueTypes.otherIssue'),
  }

  const IssueTypeDescriptionEN: Record<IssueType, string> = {
    [IssueType.MissingBookings]: t('component.reportIssue.issueTypes.missingBookings', { lng: 'en' }),
    [IssueType.BookingDetails]: t('component.reportIssue.issueTypes.bookingDetails', { lng: 'en' }),
    [IssueType.Other]: t('component.reportIssue.issueTypes.otherIssue', { lng: 'en' }),
  }

  const generateIssueID = async (): Promise<{ guid: string; shortId: string }> => {
    const uuid = crypto.randomUUID()

    const encoder = new TextEncoder()
    const data = encoder.encode(uuid)

    // Compute the hash and return the first 6 characters of the Base64-encoded hash
    const shortId = await crypto.subtle.digest('SHA-256', data).then((hashBuffer) => {
      // Convert the hash buffer to a Base64 string
      const hashArray = Array.from(new Uint8Array(hashBuffer))
      const hashBase64 = btoa(String.fromCharCode(...hashArray))

      // Make the Base64 string URL- and filename-safe
      const safeBase64 = hashBase64
          .replace(/\+/g, '-') // Replace '+' with '-'
          .replace(/\//g, '_') // Replace '/' with '_'
          .replace(/=+$/, '') // Remove '=' padding

      return safeBase64.substring(0, 6)
    })

    return {
      guid: uuid,
      shortId: shortId,
    }
  }

  const getTelemetry = async () => {
    const uap = new UAParser()
    const results = await uap.getResult().withClientHints()

    const now = new Date()

    const os =
      results.os.name === 'macOS' && results.os.version === '10.15'
        ? `${results.os.name} (${results.os.version} or greater)`
        : `${results.os.name} (${results.os.version})`

    return {
      datetime: now.toString(),
      location: pathname,
      os: os,
      browser: `${results.browser.name} (${results.browser.version})`,
      engine: `${results.engine.name} (${results.engine.version})`,
    }
  }

  const submitIssue = async (report: IssueReport) => {
    const telemetry = await getTelemetry()

    const buildSection = (title: string, content: string[]): string =>
      content ? `${title}:<br>${content.join('<br>')}<br><br>` : ''

    const sectionDetails = [
      `- Type: ${IssueTypeDescriptionEN[report.issue]}`,
      `- Booking code: ${report.bookingCode || 'n/a'}`,
      `- Message: ${report.description || 'n/a'}`,
    ]

    const sectionUser = [
      `- Email: ${report.email}`,
      `- FC status: ${fjordClubMemberStatus}`,
      `- Customer code: ${customer ? customer.customerCode : 'n/a'}`,
      `- Nationality: ${customer ? customer.nationalityCode : 'n/a'}`,
    ]

    const sectionTelemetry = [
      `- Local time: ${telemetry.datetime}`,
      `- Location: ${telemetry.location}`,
      `- OS: ${telemetry.os}`,
      `- Browser: ${telemetry.browser}`,
      `- Rendering engine: ${telemetry.engine}`,
    ]

    const sectionLogs = [`- AppInsights ID: "${report.shortID}"`]

    const message =
      `User report from MyPage<br><br>` +
      buildSection('Details', sectionDetails) +
      buildSection('User', sectionUser) +
      buildSection('Telemetry', sectionTelemetry) +
      buildSection('Logs', sectionLogs)

    return axios.post(
      FORM_RECEIVER_URL,
      {
        feedbackSource: 'mypage',
        fullName: '',
        eventType: 'ExternalBugReport',
        reason: IssueTypeDescriptionEN[report.issue],
        message: message,
        email: report.email,
        phoneNumber: '',
        bookingCode: report.bookingCode ?? null,
        customerCode: customer ? customer.customerCode : null,
        language: report.language,
        additionalDetails: IssueTypeDescriptionEN[report.issue],
        id: report.id,
        shortId: report.shortID,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${kc_token}`,
        },
      },
    )
  }

  return {
    IssueType: IssueType as typeof IssueType,
    IssueTypeDescription,
    submitIssue,
    generateIssueID,
  }
}
